import React, { FC } from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { Box, CardMedia, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
    '& p': {
      fontSize: '0.9rem',
    },
  },
  featureBoxTitle: {
    fontSize: '1.2rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
  icon: {
    height: '24px',
    width: 'auto',
  },
  largeIcon: {
    height: '36px',
    width: 'auto',
  },
  selfAlign: {
    margin: 'auto',
  },
  textAlign: {
    textAlign: 'center',
  },
}))

type FeatureElementsTitleSubtitleProps = { text: string }

type Element = {
  icon?: string
  title?: string
  content?: string
  largeIcons?: boolean
}

type FeatureElementsProps = { elements: Element[]; fourElements?: boolean; alignCenter?: boolean }

const FeatureElementsTitle: FC<FeatureElementsTitleSubtitleProps> = ({ text }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return <Typography className={classes.featureBoxTitle}>{t(text)}</Typography>
}

export const FeatureElements: FC<FeatureElementsProps> = ({
  elements,
  fourElements = false,
  alignCenter = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid container spacing={6}>
        {elements.map(({ icon, title, content, largeIcons }, index) => {
          const selectedClasses = []
          if (largeIcons) {
            selectedClasses.push(classes.largeIcon)
          } else {
            selectedClasses.push(classes.icon)
          }
          if (alignCenter) {
            selectedClasses.push(classes.selfAlign)
          }
          const selectedWrapperClass = alignCenter ? classes.textAlign : ''
          return (
            <Grid
              key={index}
              item
              xs={12}
              md={fourElements ? 3 : 4}
              className={selectedWrapperClass}
            >
              {icon && (
                <CardMedia
                  component="img"
                  alt="image"
                  image={icon}
                  className={clsx(selectedClasses)}
                />
              )}
              {title ? <FeatureElementsTitle text={title} /> : <Box mt={3} />}
              {content && <div dangerouslySetInnerHTML={{ __html: t(content) }}></div>}
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
