import React, { FC, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { SectionTitle, SectionContent } from '../Common'

const useStyles = makeStyles((theme) => ({
  teaserContainer: {
    marginLeft: theme.spacing(6),
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const ApiTeaser: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const apiHandler = useCallback(() => {
    window.location.href = 'https://api.bivial.com/docs'
  }, [])

  return (
    <>
      <Box className={classes.teaserContainer}>
        <SectionTitle title="Api-H2" />
        <SectionContent text="Api-P2" />
        <Box mt={4} mb={6} className={classes.buttonContainer}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={apiHandler}
            className={classes.getStartedButton}
          >
            {t('Api-CTA-API')}
          </Button>
        </Box>
      </Box>
    </>
  )
}
