import React, { Ref, ReactNode, useCallback, forwardRef, Dispatch, SetStateAction } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  menuItemTextProp: {
    margin: 0,
    fontSize: '1.25rem',
    textAlign: 'center',
    '& .MuiTypography-body1': {
      fontWeight: 400,
      fontSize: '0.875rem',
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: '1rem',
        opacity: '64%',
      },

      '&[data-text="Payments"] .MuiTypography-body1': {
        fontSize: '1rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Team"] .MuiTypography-body1': {
        fontSize: '1rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Solutions"] .MuiTypography-body1': {
        fontSize: '1.25rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Company"] .MuiTypography-body1': {
        fontSize: '1.25rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Products"] .MuiTypography-body1': {
        fontSize: '1.25rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Pricing"] .MuiTypography-body1': {
        fontSize: '1.25rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Partnerships"] .MuiTypography-body1': {
        fontSize: '1.25rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Talk to Us"] .MuiTypography-body1': {
        fontSize: '1.25rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Business"] .MuiTypography-body1': {
        fontSize: '1.1rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Personal"] .MuiTypography-body1': {
        fontSize: '1.1rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Incoming Payments"] .MuiTypography-body1': {
        fontSize: '1.1rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Outgoing Payments"] .MuiTypography-body1': {
        fontSize: '1.1rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Integrations"] .MuiTypography-body1': {
        fontSize: '1.1rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },

      '&[data-text="Bivial"] .MuiTypography-body1': {
        fontSize: '1.1rem ',
        fontWeight: 'bold ',
        opacity: 1,
      },
    },
  },
  menuItem: {
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 1.5),
    },
    '& .menuItemInner': {
      display: 'flex',
      padding: theme.spacing(3),
      whiteSpace: 'nowrap',
      borderTop: '4px solid transparent',
      transition: 'border-top-color 0.2s, font-weight 0.2s',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 2),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
        borderTop: '0',
        borderBottom: '4px solid transparent',
        fontSize: '1rem',

        textDecoration: 'none',
        color: 'black',
        whiteSpace: 'normal',
        // fontWeight: 400,
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& .MuiListItemIcon-root': {
        opacity: 1,
      },

      [theme.breakpoints.down('sm')]: {
        '& .MuiTypography-body1': {
          fontWeight: 400,
          fontSize: '1rem',
          opacity: '100%',
        },

        '&:hover': {
          '& [data-text="Home"] .MuiTypography-body1': {
            fontSize: '1.25rem ',
            fontWeight: 'bold ',
            opacity: 1,
          },

          '& [data-text="Use Cases"] .MuiTypography-body1': {
            fontSize: '1.25rem ',
            fontWeight: 'bold ',
            opacity: 1,
          },

          '& [data-text="About"] .MuiTypography-body1': {
            fontSize: '1.25rem ',
            fontWeight: 'bold ',
            opacity: 1,
          },
        },
      },
    },
    '&:hover .menuItemInner': {
      borderTopColor: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        borderBottomColor: theme.palette.primary.main,
        opacity: '100%',
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '& .menuItemInner': {
        borderTopColor: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
          borderBottomColor: theme.palette.secondary.main,
        },
      },
      '& .MuiListItemIcon-root': {
        opacity: 1,
        color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {},
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .forceHover': {
      borderTopColor: theme.palette.primary.main,
      borderTop: '4px solid',
    },
  },
  menuItemIcon: {
    minWidth: 24,
    // opacity: 0.64,
    // transition: 'opacity 0.2s',
    // color: theme.palette.common.black,
    justifyContent: 'center',
  },
  menuItemText: {
    margin: 0,
    fontSize: '1.25 rem',
    color: theme.palette.common.black,
  },
  arrowStyle: {
    width: '10px',
    alignItems: 'center',
    display: 'flex',
  },
}))

interface MainMenuItemProps {
  icon?: ReactNode
  primary: ReactNode
  path?: string | string[]
  setState?: Dispatch<SetStateAction<boolean>>
  className?: string
  forceHover?: boolean
}

// eslint-disable-next-line react/display-name
const MainMenuItem = forwardRef<HTMLLIElement, MainMenuItemProps>(
  ({ icon, primary, path, forceHover, setState }, ref) => {
    const match = useRouteMatch({ path: path || 'unknownPath', exact: true })
    const history = useHistory()
    const classes = useStyles()
    const redirect = useCallback(() => {
      if (typeof path === 'string') {
        history.push(path)
      } else {
        setState?.(true)
      }
    }, [history, path, setState])

    const classList = ['menuItemInner']
    if (forceHover) {
      classList.push('forceHover')
    }

    return (
      <ListItem button selected={!!match} onClick={redirect} className={classes.menuItem}>
        <Box className={classList.join(' ')}>
          <ListItemText
            primary={primary}
            className={classes.menuItemTextProp}
            data-text={primary}
            ref={ref as Ref<HTMLLIElement>}
          />
          {icon && (
            <Box className={classes.arrowStyle}>
              <ListItemIcon className={classes.menuItemIcon}>{icon}</ListItemIcon>
            </Box>
          )}
        </Box>
      </ListItem>
    )
  },
)

export default MainMenuItem
