import React, { FC, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'

import SentIcon from '../../assets/icons/icon-message-sent.svg?react'
import cityContactUs from '../../assets/images/img-message-sent-bottom@2x.png'
import { tagManagerHandler } from '../../utils'
import { PATH_ROOT } from '../../constants'
import { WebsiteTitle } from '../Common'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(0, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  reduceMarginTop: {
    marginTop: theme.spacing(-8),
  },
  hero: {
    padding: theme.spacing(8),
  },
}))

export const MessageSent: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  let timerId: NodeJS.Timeout

  tagManagerHandler('PageMessageSent')

  useEffect(() => {
    timerId = setTimeout(() => {
      history.push(PATH_ROOT)
    }, 15000)
    return () => {
      clearTimeout(timerId)
    }
  }, [])

  const buttonHandler = useCallback(() => {
    clearTimeout(timerId)
  }, [history])

  return (
    <Box>
      <Box style={{ textAlign: 'center' }}>
        <SentIcon />
      </Box>
      <Box className={classes.paddingBox}>
        <WebsiteTitle title={'MessageSent-H1'} subtitle={'MessageSent-P11'} />
        <Typography align="center" className={classes.reduceMarginTop}>
          <Link onClick={() => buttonHandler()} style={{ cursor: 'pointer' }}>
            <u>{t('MessageSent-P12')}</u>
          </Link>{' '}
          {t('MessageSent-P13')}
        </Typography>
      </Box>
      <CardMedia
        component="img"
        alt=""
        width="100%"
        image={cityContactUs}
        className={classes.hero}
      />
    </Box>
  )
}
