import { FC, default as React } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import { WebsiteTitle, SubSubFooter, CapabilitiesTable, FeatureBox } from '../Common'

import {
  PATH_BUSINESS_ACCOUNT,
  PATH_CONNECTIVITY,
  PATH_ENTREPRENEUR_ACCOUNTS,
} from '../../constants'
import { tagManagerHandler } from '../../utils'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 1),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
}))

type FeatureElement =
  | { type: 'title' | 'subtitle' | 'section' | 'content'; text: string }
  | { type: 'link'; text: string; linkTarget: string }
  | { type: 'separator' }

const capabilityElementsA: FeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Home-P4',
  },
  {
    type: 'content',
    text: 'Home-P41',
  },
  {
    type: 'section',
    text: 'Home-P42',
  },
  {
    type: 'link',
    text: 'Home-P43',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  {
    type: 'link',
    text: 'Home-P44',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  // {
  //   type: 'link',
  //   text: 'Home-P45',
  //   linkTarget: PATH_BUSINESS_ACCOUNT,
  // },
  {
    type: 'link',
    text: 'Home-P46',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  {
    type: 'section',
    text: 'Home-P47',
  },
  {
    type: 'link',
    text: 'Home-P48',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  {
    type: 'link',
    text: 'Home-P49',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
]

const capabilityElementsB: FeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Home-P6',
  },
  {
    type: 'content',
    text: 'Home-P61',
  },
  {
    type: 'link',
    text: 'Home-P62',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  {
    type: 'link',
    text: 'Home-P63',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  {
    type: 'link',
    text: 'Home-P64',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  {
    type: 'link',
    text: 'Home-P65',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
]

const capabilityElementsC: FeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Payment-Capabilities-P1',
  },
  {
    type: 'link',
    text: 'Payment-Capabilities-P3',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  {
    type: 'link',
    text: 'Payment-Capabilities-P4',
    linkTarget: PATH_ENTREPRENEUR_ACCOUNTS,
  },
  // {
  //   type: 'link',
  //   text: 'Payment-Capabilities-P5',
  //   linkTarget: PATH_BUSINESS_ACCOUNT,
  // },
  {
    type: 'separator',
  },
]

const capabilityElementsD: FeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Payment-Capabilities-H2',
  },
  {
    type: 'content',
    text: 'Payment-Capabilities-P2',
  },
  {
    type: 'link',
    text: 'Payment-Capabilities-P6',
    linkTarget: PATH_CONNECTIVITY,
  },
]

export const PaymentCapabilitiesPage: FC = () => {
  const classes = useStyles()

  tagManagerHandler('PaymentCapabilitiesHome')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Payment-Capabilities-H1'} subtitle={'Home-P11'} />
          {/* <Hidden mdUp> */}
          <Box mt={-12} />
          {/* </Hidden> */}
          {/* <Grid container className={classes.featureContainer}>
            <Grid item xs={12} md={4}>
              <FeatureBox isBlackBg={false} elements={capabilityElementsA} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Hidden mdUp>
                <Box mt={-6} />
              </Hidden>
              <FeatureBox isBlackBg={false} elements={capabilityElementsB} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Hidden mdUp>
                    <Box mt={-3} />
                  </Hidden>
                  <FeatureBox isBlackBg={true} elements={capabilityElementsC} />
                </Grid>
                <Grid item xs={12}>
                  <Hidden mdUp>
                    <Box mt={-3} />
                  </Hidden>
                  <FeatureBox isBlackBg={false} elements={capabilityElementsD} />
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid container>
            <Grid item xs={12}>
              <Hidden mdUp>
                <Box mt={-3} />
              </Hidden>
              <CapabilitiesTable search={true} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
