import { FC, default as React } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Hidden } from '@material-ui/core'
import { WebsiteTitle, MediaObject, SubSubFooter } from '../Common'
import { ClientFundsTeaser } from './ClientFundsTeaser'
import { tagManagerHandler } from '../../utils'
import coin from '../../assets/images/img-client-funds-coin@2x.png'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  icon: {
    height: '24px',
    width: 'auto',
  },
}))

export const ClientFundsAccountPage: FC = () => {
  const classes = useStyles()

  tagManagerHandler('PageClientFundsAccount')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Client-Funds-Account-H1'} />
          <Hidden mdUp>
            <Box mt={-9} />
          </Hidden>
          <Grid container className={classes.featureContainer}>
            <Grid container className={classes.mediaObjectContainer}>
              <MediaObject imagePlacement={'right'} image={coin} imageSize={'large'}>
                <ClientFundsTeaser />
              </MediaObject>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
