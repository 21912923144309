import { FC, default as React } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  WebsiteTitle,
  MediaObject,
  FeatureElements,
  SectionTitle,
  SubSubFooter,
  SectionContent,
} from '../Common'
import { ApiTeaser } from './ApiTeaser'
import { tagManagerHandler } from '../../utils'
import apiDocs from '../../assets/images/img-api-docs@2x.png'
import apiRequest from '../../assets/images/img-api-1@2x.png'
import apiCurl from '../../assets/images/img-api-2@2x.png'
import icon11 from '../../assets/icons/img-api-11.svg'
import icon12 from '../../assets/icons/img-api-12.svg'
import icon13 from '../../assets/icons/img-api-13.svg'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  centerSection: {
    maxWidth: '700px',
    margin: 'auto',
    '& > p': {
      textAlign: 'center',
    },
    '& > div > h2': {
      textAlign: 'center',
    },
  },
  icon: {
    height: '24px',
    width: 'auto',
  },
  iconLarge: {
    height: '36px',
    width: 'auto',
    marginBottom: theme.spacing(4),
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  iconFeatureContainer: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  button: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

type SimpleFeatureElement = { icon?: string; title: string; content: string }

const featuresA: SimpleFeatureElement[] = [
  {
    icon: icon11,
    title: 'Api-H4',
    content: 'Api-P4',
  },
  {
    icon: icon12,
    title: 'Api-H5',
    content: 'Api-P5',
  },
  {
    icon: icon13,
    title: 'Api-H6',
    content: 'Api-P6',
  },
]

const featuresB: SimpleFeatureElement[] = [
  {
    title: 'Api-H9',
    content: 'Api-P9',
  },
  {
    title: 'Api-H10',
    content: 'Api-P10',
  },
  {
    title: 'Api-H11',
    content: 'Api-P11',
  },
]

type ApiListItemProps = { content: string }

const ApiListItem: FC<ApiListItemProps> = ({ content }) => {
  const { t } = useTranslation()

  return (
    <Box mt={4}>
      <Typography>{t(content)}</Typography>
    </Box>
  )
}

export const ApiPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  tagManagerHandler('PageApi')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <Box className={classes.centerSection}>
            <WebsiteTitle title={'Api-H1'} subtitle={'Api-P1'} />
          </Box>
          <Grid container className={classes.featureContainer}>
            <Grid container className={classes.mediaObjectContainer}>
              <MediaObject imagePlacement={'right'} image={apiDocs} imageSize={'large'}>
                <ApiTeaser />
              </MediaObject>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={6} mt={-9}>
                <Box className={classes.centerSection}>
                  <SectionTitle title={'Api-H8'} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements elements={featuresB} />
            </Grid>
          </Grid>
          <Grid container className={classes.featureContainer}>
            <Grid container className={classes.mediaObjectContainer}>
              <MediaObject imagePlacement={'left'} image={apiRequest} fullHeight={false}>
                <Box pl={12} mt={6}>
                  <SectionTitle title={'Api-H7'} />
                  <ApiListItem content={'Api-P61'} />
                  <ApiListItem content={'Api-P62'} />
                  <ApiListItem content={'Api-P63'} />
                  <ApiListItem content={'Api-P64'} />
                </Box>
              </MediaObject>
            </Grid>
          </Grid>
          <Box mb={9} mt={-12}>
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.centerSection}>
                  <SectionTitle title={'Api-H3'} />
                  <SectionContent text={'Api-P3'} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements elements={featuresA} />
            </Grid>
          </Grid>
          <Grid container className={classes.featureContainer}>
            <Grid container className={classes.mediaObjectContainer}>
              <MediaObject imagePlacement={'right'} image={apiCurl} imageSize={'large'}>
                <SectionTitle title="Api-H12" />
              </MediaObject>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
