import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import flag1 from '../../assets/images/flag1.png'
import flag2 from '../../assets/images/flag2.png'
import { Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'
import { WebsiteTitle } from '../Common'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
}))

export const ImpressumPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  tagManagerHandler('PageImpressum')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Impressum'} />
          <Grid container className={classes.featureContainer}></Grid>
        </Container>
      </Box>
      <Box className={classes.featureContainer}>
        <Grid container spacing={2}>
          <Grid container item xs={12} direction={'column'}>
            <Typography component={'span'}>
              <h3>{t('Impressum-P1')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P2')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component={'span'}>
              <h3>{t('Impressum-P3')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P4')}
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P5')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P6')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P7')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P8')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P9')}
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P10')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P101')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P102')}
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P103')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P11')}</h3>
            </Typography>
            <Grid item xs={1}>
              <CardMedia component="img" alt="" image={flag1} />
            </Grid>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P12')}</h3>
            </Typography>
            <Grid item xs={1}>
              <CardMedia component="img" alt="" image={flag2} />
            </Grid>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P13')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P14')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P15')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P16')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P17')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P18')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P19')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P20')}
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P21')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P22')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              <Link
                target="_blank"
                href={
                  'https://www.finma.ch/en/~/media/finma/dokumente/bewilligungstraeger/pdf/fintech.pdf?la=en'
                }
                rel="noreferrer"
              >
                <ins>{t('Impressum-P23')}</ins>
              </Link>
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P24')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P25')}
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P26')}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction={'column'}>
            <Typography component="div">
              <h3>{t('Impressum-P27')}</h3>
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P28')}
            </Typography>
            <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
              {t('Impressum-P29')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
