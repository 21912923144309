import React, { FC, useCallback } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { GeneralLayout } from '../GeneralLayout'
import { Button } from '@material-ui/core'
import { text } from 'stream/consumers'
import { PATH_HOME } from '../../constants'

const useStyles = makeStyles((theme) => ({
  blackBg: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: '100% !important',
    color: 'white',
    padding: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowTitleWhite: {
    color: 'white',
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowSubTitle: {
    marginTop: theme.spacing(4),
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
    '& a': {
      textDecoration: 'underline',
      color: 'white',
      fontWeight: 400,
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    border: '1px solid white',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  signUpButton: {
    fontWeight: 700,
    minWidth: 200,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
}))

export const NotFoundPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )
  return (
    <GeneralLayout>
      <Container className={classes.blackBg}>
        <Box>
          <Grid container direction={'column'} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography
                variant={'h2'}
                align="center"
                gutterBottom
                className={clsx(classes.rowTitle, classes.rowTitleWhite)}
              >
                <div dangerouslySetInnerHTML={{ __html: t('NotFound-H1') }} />
              </Typography>
            </Grid>

            <Box mt={4} className={classes.buttonContainer}>
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                onClick={() => buttonHandler('/')}
                className={classes.signUpButton}
              >
                {t('NotFound-CTA')}
              </Button>
            </Box>
            <Typography
              align="center"
              gutterBottom
              className={clsx(classes.rowTitleWhite, classes.rowSubTitle)}
            >
              <div dangerouslySetInnerHTML={{ __html: t('NotFound-P1') }} />
            </Typography>
          </Grid>
        </Box>
      </Container>
    </GeneralLayout>
  )
}
