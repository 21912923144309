import { FC, default as React } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {
  WebsiteTitle,
  FeatureElements,
  MediaObject,
  SectionTitle,
  SectionContent,
  SubSubFooterV4,
} from '../Common'
import { DepositGuaranteedTeaser } from './DepositGuaranteedTeaser'
import { tagManagerHandler } from '../../utils'
import interior from '../../assets/images/img-building-interior@2x.png'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  centerSection: {
    maxWidth: '600px',
    margin: '30px auto 30px',
    '& > p': {
      textAlign: 'center',
    },
    '& > div > h2': {
      textAlign: 'center',
    },
  },
}))

type SimpleFeatureElement = { title: string; content: string }

const features: SimpleFeatureElement[] = [
  {
    title: 'Deposit-Guaranteed-Account-H4',
    content: 'Deposit-Guaranteed-Account-P4',
  },
  {
    title: 'Deposit-Guaranteed-Account-H6',
    content: 'Deposit-Guaranteed-Account-P6',
  },
  {
    title: 'Deposit-Guaranteed-Account-H7',
    content: 'Deposit-Guaranteed-Account-P7',
  },
]

export const DepositGuaranteedAccountPage: FC = () => {
  const classes = useStyles()

  tagManagerHandler('PageDepositGuaranteed')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Deposit-Guaranteed-Account-H1'} />
          <Grid container className={classes.featureContainer}>
            <Grid container>
              <MediaObject imagePlacement={'right'} image={interior} imageSize={'large'}>
                <DepositGuaranteedTeaser />
              </MediaObject>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Hidden smDown>
                  <Box mt={-8} />
                </Hidden>
                <Box className={classes.centerSection}>
                  <SectionTitle title="Deposit-Guaranteed-Account-H3" />
                  <SectionContent text="Deposit-Guaranteed-Account-P3" />
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.simpleFeatureContainer}>
                <FeatureElements elements={features} />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooterV4 />
    </>
  )
}
