import { FC, default as React, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {
  WebsiteTitle,
  MediaObject,
  SectionTitle,
  SubSubFooterV4,
  HeroImage,
  FeatureElements,
} from '../Common'
import { MerchantPaymentTeaser } from './MerchantPaymentTeaser'
import { tagManagerHandler } from '../../utils'
import hero from '../../assets/images/img-merchant-accounts-header.png'
import allInOne from '../../assets/images/img-all-in-one-dash@2x.png'
import merchantPayment from '../../assets/images/img-merchant-accounts-payment.png'
import merchantLogos from '../../assets/images/img-merchant-accounts-logos.png'
import { Button, CardMedia, Typography } from '@material-ui/core'
import { PATH_PAYMENT_CAPABILITIES_INC_MER } from '../../constants'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  hero: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  centerSection: {
    maxWidth: '700px',
    margin: 'auto',
    '& > p': {
      textAlign: 'center',
    },
    '& > div > h2': {
      textAlign: 'center',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

type SimpleFeatureElement = { title: string; content: string }

const features: SimpleFeatureElement[] = [
  {
    title: 'Merchant-Payment-Account-H21',
    content: 'Merchant-Payment-Account-P21',
  },
  {
    title: 'Merchant-Payment-Account-H22',
    content: 'Merchant-Payment-Account-P22',
  },
  {
    title: 'Merchant-Payment-Account-H23',
    content: 'Merchant-Payment-Account-P23',
  },
  {
    title: 'Merchant-Payment-Account-H24',
    content: 'Merchant-Payment-Account-P24',
  },
  {
    title: 'Merchant-Payment-Account-H25',
    content: 'Merchant-Payment-Account-P25',
  },
  {
    title: 'Merchant-Payment-Account-H26',
    content: 'Merchant-Payment-Account-P26',
  },
]

export const MerchantPaymentAccountPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(() => {
    history.push(PATH_PAYMENT_CAPABILITIES_INC_MER)
  }, [])

  tagManagerHandler('PageDepositGuaranteed')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle
            title={'Merchant-Payment-Account-H1'}
            subtitle={'Merchant-Payment-Account-P11'}
            danger
          />
          <CardMedia
            component="img"
            alt=""
            height="100%"
            image={merchantPayment}
            className={classes.hero}
          />
          <Grid container className={classes.featureContainer}>
            <Grid container>
              <Grid item xs={12} className={classes.simpleFeatureContainer}>
                <FeatureElements elements={features} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.centerSection}>
              <SectionTitle title={'Merchant-Payment-Account-H4'} danger />
            </Box>
            <Box mb={9} mt={6}>
              <Box className={classes.centerSection}>
                <Box mt={4} className={classes.buttonContainer}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={buttonHandler}
                    className={classes.getStartedButton}
                  >
                    {t('Merchant-Payment-Account-CTA')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
      <SubSubFooterV4 />
    </>
  )
}
