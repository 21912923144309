import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
  },
  row2: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.25rem',
    },
  },
}))

export const PrivacyPolicyPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  tagManagerHandler('PagePrivacyPolice')

  return (
    <>
      <Container>
        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              className={classes.pageTitle}
            >
              {t('Privacy')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row2}>
          <Grid container spacing={2}>
            <Grid container item xs={12} justify={'center'}>
              <Typography component={'span'}>
                <h1>{t('Privacy-H1')}</h1>
              </Typography>
              <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
                {t('Privacy-P11')}
              </Typography>
              <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
                {t('Privacy-P12')}
              </Typography>
            </Grid>

            <Grid container item xs={12} justify={'center'}>
              <Typography component={'span'} text-align={'justify'}>
                <h1>{t('Privacy-H2')}</h1>
              </Typography>
              <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
                {t('Privacy-P21')}
              </Typography>
              <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
                {t('Privacy-P22')}
              </Typography>
              <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
                {t('Privacy-P23')}
              </Typography>
              <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
                {t('Privacy-P24')}
              </Typography>
            </Grid>

            <Grid container item xs={12} justify={'center'}>
              <Typography component="div">
                <h1>{t('Privacy-H3')}</h1>
              </Typography>
              <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
                {t('Privacy-P31')}
              </Typography>
              <Typography variant={'body2'} paragraph={true} text-align={'justify'}>
                {t('Privacy-P32')} &nbsp;
                <a href="mailto:info@bivial.com" target="_black">
                  info@bivial.com
                </a>
                . {t('Privacy-P33')} (
                <a href="http://www.edoeb.admin.ch" target="_blank" rel="noreferrer">
                  http://www.edoeb.admin.ch
                </a>
                ).
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
