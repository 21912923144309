import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '2.75rem',
    marginTop: theme.spacing(6),
    width: '100%',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
}))

export const WebsiteTitle: FC<{
  title: string
  subtitle?: string
  danger?: boolean
}> = ({ title, subtitle, danger }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Box mb={subtitle ? 9 : 3}>
        <Grid item xs={12} md={12} container justify={'center'}>
          <Typography
            component="h1"
            variant={'h1'}
            align="center"
            color="textPrimary"
            gutterBottom
            className={classes.pageTitle}
          >
            {danger ? <div dangerouslySetInnerHTML={{ __html: t(title) }} /> : t(title)}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle1" align="center" component={'div'} gutterBottom>
              {danger ? <div dangerouslySetInnerHTML={{ __html: t(subtitle) }} /> : t(subtitle)}
            </Typography>
          )}
        </Grid>
      </Box>
    </>
  )
}
