import React, { FC, useCallback } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { CardMedia, makeStyles } from '@material-ui/core'
import IconArrow from '../../../assets/icons/icon-top-right-arrow.svg?react'

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(2, 6),
    marginTop: theme.spacing(0),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(2, 4),
      textAlign: 'left',
    },
  },
  blackBg: {
    backgroundColor: '#000',
    color: '#fff',
    '& a': {
      backgroundColor: '#000',
      color: '#fff',
      textDecoration: 'underline',
      fontWeight: 400,
    },
  },
  content: {
    marginTop: theme.spacing(2),
    '& p': {
      fontSize: '0.9rem',
    },
  },
  link: {
    cursor: 'pointer',
    marginTop: theme.spacing(1.5),
    '& p': {
      fontSize: '0.8rem',
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  arrow: {
    marginRight: theme.spacing(1),
  },
  featureBoxTitle: {
    fontSize: '1.8rem',
    width: '100%',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
  featureBoxSubtitle: {
    fontSize: '1.2rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
  featureBoxSection: {
    fontSize: '1rem',
    marginTop: theme.spacing(4),
    width: '100%',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
  separator: {
    height: '45px',
  },
  icon: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(4),
    height: '24px',
    width: 'auto',
  },
}))

type FeatureBoxTitleSubtitleProps = { text?: string }

type FeatureBoxProps = {
  isBlackBg: boolean
  elements: {
    type: 'icon' | 'title' | 'subtitle' | 'section' | 'content' | 'link' | 'separator'
    text?: string
    linkTarget?: string
  }[]
}

const FeatureBoxTitle: FC<FeatureBoxTitleSubtitleProps> = ({ text = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return <Typography className={classes.featureBoxTitle}>{t(text)}</Typography>
}

const FeatureBoxSubtitle: FC<FeatureBoxTitleSubtitleProps> = ({ text = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return <Typography className={classes.featureBoxSubtitle}>{t(text)}</Typography>
}

const FeatureBoxSection: FC<FeatureBoxTitleSubtitleProps> = ({ text = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return <Typography className={classes.featureBoxSection}>{t(text)}</Typography>
}

const Separator: FC = () => {
  const classes = useStyles()
  return <div className={classes.separator} />
}

export const FeatureBox: FC<FeatureBoxProps> = ({ isBlackBg, elements }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const clickHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  const classesRow = isBlackBg ? `${classes.row} ${classes.blackBg}` : classes.row

  return (
    <>
      <Box className={clsx(classesRow)}>
        {elements.map((element, index) => {
          switch (element.type) {
            case 'icon':
              return (
                <CardMedia
                  component="img"
                  key={index}
                  image={element.text}
                  className={classes.icon}
                />
              )
            case 'title':
              return <FeatureBoxTitle key={index} text={element.text} />
            case 'subtitle':
              return <FeatureBoxSubtitle key={index} text={element.text} />
            case 'section':
              return <FeatureBoxSection key={index} text={element.text} />
            case 'content':
              return (
                <Grid key={index} item xs={12} className={classes.content}>
                  {element.text && (
                    <div dangerouslySetInnerHTML={{ __html: t(element.text) }}></div>
                  )}
                </Grid>
              )
            case 'link':
              return (
                <Grid key={index} item xs={12} className={classes.link}>
                  <Typography onClick={() => clickHandler(element?.linkTarget)}>
                    <IconArrow className={classes.arrow} />
                    {t(element.text || '')}
                  </Typography>
                </Grid>
              )
            case 'separator':
              return <Separator />
            default:
              return null
          }
        })}
      </Box>
    </>
  )
}
