import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import CardMedia from '@material-ui/core/CardMedia'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: '180px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: '36px',
    width: 'auto',
    margin: '16px auto',
  },
}))

type Element = { title: string; icon: string }

type FeatureElementsProps = { elements: Element[] }

export const FeatureIcons: FC<FeatureElementsProps> = ({ elements }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={6} justifyContent="space-between">
        {elements.map(({ title, icon }, index) => {
          return (
            <Grid key={index} item xs={12} md={3} className={classes.content}>
              <CardMedia component="img" alt="" image={icon} className={classes.icon} />
              <div dangerouslySetInnerHTML={{ __html: t(title) }}></div>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
