import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '1rem',
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

export const SectionContent: FC<{
  text: string
  danger?: boolean
}> = ({ text, danger = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Typography align="left" color="textPrimary" gutterBottom className={classes.text}>
        {danger ? <div dangerouslySetInnerHTML={{ __html: t(text) }} /> : t(text)}
      </Typography>
    </>
  )
}
