import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    marginTop: theme.spacing(6),
    width: '100%',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(-4),
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(-4),
      fontSize: '1.6rem',
    },
  },
}))

export const SectionTitle: FC<{
  title: string
  danger?: boolean
}> = ({ title, danger = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={12} md={12} container justify={'center'}>
        <Typography
          component="h2"
          variant={'h2'}
          align="left"
          color="textPrimary"
          gutterBottom
          className={classes.title}
        >
          {danger ? <div dangerouslySetInnerHTML={{ __html: t(title) }} /> : t(title)}
        </Typography>
      </Grid>
    </>
  )
}
