import React, { FC, useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { PATH_PRICING_PAGES_CATEGORY } from '../../constants'
import { tagManagerHandler } from '../../utils'
import { SubSubFooterV2 } from '../Common/SubSubFooter'
import { WebsiteTitle } from '../Common'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 0),
    },
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  blackBg: {
    backgroundColor: '#000',
    color: '#fff',
    '& a': {
      backgroundColor: '#000',
      color: '#fff',
      textDecoration: 'underline',
      fontWeight: 400,
    },
  },
  paddingCell: {
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  fontLight: {
    fontWeight: 300,
  },
  subtitleBoxIcon: {
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
  },
  menuItem: {
    flexWrap: 'wrap',
    padding: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },

    '& .MuiMenuItem-root': {
      width: '25%',
      justifyContent: 'center',
      whiteSpace: 'normal',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },

    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },

    '& .img:hover': { backgroundColor: theme.palette.secondary.main },

    '& .menuItemInner': {
      display: 'flex',
      alignItems: 'top',
      paddingTop: theme.spacing(0),
      borderTop: '8px solid transparent',
      transition: 'border-top-color 0.2s, font-weight 0.2s',
      position: 'relative',
      top: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',

      '& .MuiListItemIcon-root': {
        opacity: 1,
      },
    },
    '&:hover .menuItemInner': {
      backgroundColor: 'green',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      background: theme.palette.secondary.main,
      '& .menuItemInner': {
        background: theme.palette.secondary.main,
      },
      '& .MuiListItemIcon-root': {
        opacity: 1,
        // color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {},
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  maxWidth: {
    maxWidth: '1000px',
    width: '100%',
    margin: '0 auto',
  },
  row: {
    padding: theme.spacing(2, 6),
    marginTop: theme.spacing(0),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(1),
      textAlign: 'left',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  pageTitle: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
      fontSize: '2.25rem',
    },
  },
  pricingMenu: {
    float: 'left',
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 700,
    cursor: 'pointer',
    '&:last-child': {
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: theme.spacing(0),
      },
    },
  },
  selected: {
    borderBottom: '4px solid #ef2828',
  },
  horizontalScrollerWrapper: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      paddingLeft: '395px',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  horizontalScroller: {
    position: 'relative',
    minWidth: '780px',
  },
}))

enum PricingType {
  Application = 'Application',
  Account = 'Account',
  BankWallet = 'BankWallet',
  Merchant = 'Merchant',
  Card = 'Card',
}

const categoryString = (category: PricingType): string => {
  switch (category) {
    case PricingType.Application:
      return 'application'
    case PricingType.Account:
      return 'account'
    case PricingType.BankWallet:
      return 'banks-wallet'
    case PricingType.Merchant:
      return 'merchant'
    case PricingType.Card:
      return 'card'
    default:
      return 'application'
  }
}

const categoryEnum = (category: string): PricingType => {
  switch (category) {
    case 'application':
      return PricingType.Application
    case 'account':
      return PricingType.Account
    case 'bank-wallet':
      return PricingType.BankWallet
    case 'merchant':
      return PricingType.Merchant
    case 'card':
      return PricingType.Card
    default:
      return PricingType.Application
  }
}

type CategoryParams = {
  category: string
}

export const BusinessPricingPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { category } = useParams<CategoryParams>()
  const { t } = useTranslation()

  const [selectedCategory, setSelectedCategory] = useState<PricingType>(
    categoryEnum(category) || PricingType.Application,
  )

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  tagManagerHandler('PageBusinessPricing')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Pricing-H1'} subtitle={'Pricing-P1'} danger />
          <Grid item xs={12} className={classes.maxWidth}>
            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
              className={classes.horizontalScrollerWrapper}
            >
              <div className={classes.horizontalScroller}>
                <Typography
                  className={clsx(
                    classes.pricingMenu,
                    selectedCategory === PricingType.Application && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedCategory(PricingType.Application)
                    buttonHandler(
                      PATH_PRICING_PAGES_CATEGORY.replace(
                        ':category',
                        categoryString(PricingType.Application),
                      ),
                    )
                  }}
                >
                  {t('Pricing-Application')}
                </Typography>
                <Typography
                  className={clsx(
                    classes.pricingMenu,
                    selectedCategory === PricingType.Account && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedCategory(PricingType.Account)
                    buttonHandler(
                      PATH_PRICING_PAGES_CATEGORY.replace(
                        ':category',
                        categoryString(PricingType.Account),
                      ),
                    )
                  }}
                >
                  {t('Pricing-Account')}
                </Typography>
                <Typography
                  className={clsx(
                    classes.pricingMenu,
                    selectedCategory === PricingType.BankWallet && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedCategory(PricingType.BankWallet)
                    buttonHandler(
                      PATH_PRICING_PAGES_CATEGORY.replace(
                        ':category',
                        categoryString(PricingType.BankWallet),
                      ),
                    )
                  }}
                >
                  {t('Pricing-Bank-Wallet')}
                </Typography>
                <Typography
                  className={clsx(
                    classes.pricingMenu,
                    selectedCategory === PricingType.Merchant && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedCategory(PricingType.Merchant)
                    buttonHandler(
                      PATH_PRICING_PAGES_CATEGORY.replace(
                        ':category',
                        categoryString(PricingType.Merchant),
                      ),
                    )
                  }}
                >
                  {t('Pricing-Merchant')}
                </Typography>
                <Typography
                  className={clsx(
                    classes.pricingMenu,
                    selectedCategory === PricingType.Card && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedCategory(PricingType.Card)
                    buttonHandler(
                      PATH_PRICING_PAGES_CATEGORY.replace(
                        ':category',
                        categoryString(PricingType.Card),
                      ),
                    )
                  }}
                >
                  {t('Pricing-Card')}
                </Typography>
              </div>
            </Grid>
            {selectedCategory === PricingType.Application && (
              <Box className={clsx(classes.row)}>
                <Grid container>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <Typography component="h4" variant={'h4'} className={classes.fontLight}>
                      <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-01') }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {' '}
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-02') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-03') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-04') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-05') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-06') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-07') }} />
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedCategory === PricingType.BankWallet && (
              <Box className={clsx(classes.row)}>
                <Grid container>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <Typography component="h4" variant={'h4'} className={classes.fontLight}>
                      <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-08') }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {' '}
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-09') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-10') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-11') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-12') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-13') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-14') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-15') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-16') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-17') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-18') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-19') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-20') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-21') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-22') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-23') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-24') }} />
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedCategory === PricingType.Card && (
              <Box className={clsx(classes.row)}>
                <Grid container>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <Typography component="h4" variant={'h4'} className={classes.fontLight}>
                      <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-25') }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.paddingCell)}>
                    {' '}
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-26') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-27') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-28') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-29') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-30') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-31') }} />
                  </Grid>
                  {/* <Grid item xs={6} className={classes.paddingCell}>
                    <Typography component="h4" variant={'h4'} className={classes.fontLight}>
                      <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-32') }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-33') }} />
                  </Grid> */}
                </Grid>
              </Box>
            )}
            {selectedCategory === PricingType.Merchant && (
              <Box className={clsx(classes.row)}>
                <Grid container>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <Typography component="h4" variant={'h4'} className={classes.fontLight}>
                      <div dangerouslySetInnerHTML={{ __html: t('Pricing-Merchant-Fees') }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.paddingCell)}>
                    {' '}
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-B-01') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-B-02') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-B-03') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-B-04') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-B-05') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-B-06') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-B-07') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-22') }} />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-B-08') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-22') }} />
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedCategory === PricingType.Account && (
              <Box className={clsx(classes.row)}>
                <Grid container>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <Typography component="h4" variant={'h4'} className={classes.fontLight}>
                      <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-36') }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {' '}
                  </Grid>
                  <Grid item xs={6} className={classes.paddingCell}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-34') }} />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.blackBg, classes.paddingCell)}>
                    <div dangerouslySetInnerHTML={{ __html: t('Pricing-A-35') }} />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Container>
      </Box>
      <SubSubFooterV2 />
    </>
  )
}
