const incomingMerchantPaymentsCards = {
  type: 'incoming_merchant_payments_cards',
  sticky_note: '',
  visible_columns: ['method', 'currency', 'region', 'operation'],
  hidden_columns: [],
  column_names: {
    operation: '05',
    method: '06',
    currency: '16',
    region: '04',
  },
  data: [
    {
      method: 'Visa',
      currency: 'All Currencies',
      region: 'Worldwide',
      operation: 'Refund, Push to Card',
    },
    {
      method: 'Mastercard',
      currency: 'All Currencies',
      region: 'Worldwide',
      operation: 'Refund, Push to Card',
    },
  ],
}

const exportedObject = incomingMerchantPaymentsCards

export default exportedObject
