import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import MenuArrow from '../../../assets/icons/menu-arrow.svg?react'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '1rem',
    textDecoration: 'none',
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

type Link = { text: string; url: string }
type LinkArray = Link[]

export const LinkSection: FC<{
  links: LinkArray
}> = ({ links }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Box mt={4}>
        <Grid container>
          {links.map(({ text, url }, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Box mb={2}>
                <a href={url} className={classes.text}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography align="left" color="textPrimary" gutterBottom>
                        {t(text)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <MenuArrow />
                    </Grid>
                  </Grid>
                </a>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}
