import { FC, default as React, useCallback } from 'react'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { CardMedia, Hidden } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { WebsiteTitle, MediaObject, FeatureElements, SectionTitle, SubSubFooter } from '../Common'
import { VirtualIbanAccountsTeaser } from './VirtualIbanAccountsTeaser'
import { tagManagerHandler } from '../../utils'
import vban from '../../assets/images/img-vban-1@2x.png'
import vbanNetwork from '../../assets/images/img-vban-2@2x.png'
import { PATH_CARD } from '../../constants'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  mediaObjectContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  centerSection: {
    maxWidth: '700px',
    margin: '-60px auto 60px',
    '& > div > h2': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  icon: {
    height: '24px',
    width: 'auto',
  },
  iconLarge: {
    height: '36px',
    width: 'auto',
    marginBottom: theme.spacing(4),
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  iconFeatureContainer: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  button: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

type SimpleFeatureElement = { title: string; content: string }

const features: SimpleFeatureElement[] = [
  {
    title: 'VirtualIbanAccounts-H6',
    content: 'VirtualIbanAccounts-P6',
  },
  {
    title: 'VirtualIbanAccounts-H7',
    content: 'VirtualIbanAccounts-P7',
  },
  {
    title: 'VirtualIbanAccounts-H8',
    content: 'VirtualIbanAccounts-P8',
  },
  {
    title: 'VirtualIbanAccounts-H9',
    content: 'VirtualIbanAccounts-P9',
  },
  {
    title: 'VirtualIbanAccounts-H10',
    content: 'VirtualIbanAccounts-P10',
  },
  {
    title: 'VirtualIbanAccounts-H11',
    content: 'VirtualIbanAccounts-P11',
  },
]

export const VirtualIbanAccountsPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  tagManagerHandler('PageCard')

  const clickHandlerCard = useCallback(() => {
    history.push(PATH_CARD)
  }, [history])

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'VirtualIbanAccounts-H1'} />
          <Hidden mdUp>
            <Box mt={-12} />
          </Hidden>
          <Grid container className={classes.featureContainer}>
            <Grid container className={classes.mediaObjectContainer}>
              <MediaObject imagePlacement={'right'} image={vban} fullHeight={false}>
                <VirtualIbanAccountsTeaser />
              </MediaObject>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.centerSection}>
                <SectionTitle title="VirtualIbanAccounts-H3" />
              </Box>
            </Grid>
          </Grid>
          <Grid container className={classes.featureContainer}>
            <CardMedia component="img" alt="image" image={vbanNetwork} />
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.centerSection} style={{ marginTop: '30px' }}>
                <Hidden mdUp>
                  <Box mt={12} />
                </Hidden>
                <SectionTitle title="VirtualIbanAccounts-H4" />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements elements={features} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
