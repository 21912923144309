import { createMuiTheme } from '@material-ui/core'

export const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    //52
    h1: {
      fontSize: '3.25rem',
      fontWeight: 'bold',
    },
    //40
    h2: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
    },

    //24
    h3: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },

    //20
    h4: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: ' 1.125rem',
      fillOpacity: 60,
    },

    //12
    h6: {
      fontSize: '0.75rem',
      fontWeight: 300,
    },

    caption: {
      fontFamily: ' "Helvetica Neue", "Arial", sans-serif',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      opacity: '0.64',
    },

    //18
    subtitle1: {
      fontFamily: '"Helvetica Neue", "Arial", sans-serif',
      fontSize: '1rem',
      lineHeight: '130%',
    },

    //20
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },

    //16
    body1: {
      fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    },

    //16
    body2: {
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#EF2828',
      light: '#E5E5E5',
    },
    error: {
      main: '#EF2828',
    },
    warning: {
      main: '#EF8019',
    },
    text: {
      primary: '#000000',
    },
  },

  overrides: {
    MuiTableCell: {
      head: {
        fontSize: '0.75rem',
        opacity: '0.64',
      },
      root: {
        height: 48,
        padding: '6px 16px',
        fontSize: '0.875rem',
        '& .MuiIconButton-root': {
          width: 36,
          height: 36,
          padding: 10,
        },
      },
    },
    MuiTableRow: {
      root: {
        '& .actionButtons': {
          opacity: 0,
        },
        '&:hover .actionButtons': {
          opacity: 1,
          transition: 'ease opacity 0.3s',
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.857)',
      },
    },
    MuiIconButton: {
      root: {
        color: '#000000',
      },
    },
    MuiButton: {
      root: {
        minWidth: 138,
        minHeight: 48,
        fontSize: '1rem',
        boxShadow: 'none',
      },
    },
  },
})
