import { FC, default as React } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import {
  WebsiteTitle,
  SubSubFooter,
  HeroImage,
  FeatureElements,
  SectionTitle,
  SectionContent,
} from '../Common'
import imgHero2x from '../../assets/images/img-use-cases-1@2x.png'

import icon1 from '../../assets/icons/img-use-cases-1.svg'
import icon2 from '../../assets/icons/img-use-cases-2.svg'
import icon3 from '../../assets/icons/img-use-cases-3.svg'
import icon4 from '../../assets/icons/img-use-cases-4.svg'
import icon5 from '../../assets/icons/img-use-cases-5.svg'
import icon6 from '../../assets/icons/img-use-cases-6.svg'
import icon7 from '../../assets/icons/img-use-cases-7.svg'
import icon8 from '../../assets/icons/img-use-cases-8.svg'
import icon9 from '../../assets/icons/img-use-cases-9.svg'
import icon10 from '../../assets/icons/img-use-cases-10.svg'
import icon11 from '../../assets/icons/img-use-cases-11.svg'

import { tagManagerHandler } from '../../utils'
import { Hidden, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  iconFeatureContainer: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  bannerContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },
}))

type FeatureElement = { icon: string; title: string; content: string }
type SimpleFeatureElement = { icon: string; title: string; largeIcons: boolean }
type UseCaseListItemProps = { content: string }

const UseCaseListItem: FC<UseCaseListItemProps> = ({ content }) => {
  const { t } = useTranslation()

  return (
    <Box mb={2}>
      <Typography>{t(content)}</Typography>
    </Box>
  )
}

const featuresA: FeatureElement[] = [
  {
    icon: icon8,
    title: 'Usecases-H2',
    content: 'Usecases-P2',
  },
  {
    icon: icon11,
    title: 'Usecases-H3',
    content: 'Usecases-P3',
  },
  {
    icon: icon4,
    title: 'Usecases-H4',
    content: 'Usecases-P4',
  },
]

const featuresB: SimpleFeatureElement[] = [
  {
    icon: icon9,
    title: 'Usecases-H51',
    largeIcons: true,
  },
  {
    icon: icon2,
    title: 'Usecases-H52',
    largeIcons: true,
  },
  {
    icon: icon5,
    title: 'Usecases-H53',
    largeIcons: true,
  },
  {
    icon: icon3,
    title: 'Usecases-H54',
    largeIcons: true,
  },
  // {
  //   icon: icon5,
  //   title: 'Usecases-H55',
  //   largeIcons: true,
  // },
  // {
  //   icon: icon3,
  //   title: 'Usecases-H56',
  //   largeIcons: true,
  // },
  // {
  //   icon: icon7,
  //   title: 'Usecases-H57',
  //   largeIcons: true,
  // },
  // {
  //   icon: icon6,
  //   title: 'Usecases-H58',
  //   largeIcons: true,
  // },
]

export const UseCasesPage: FC = () => {
  const classes = useStyles()

  tagManagerHandler('PageUseCases')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Usecases-H1'} subtitle={'Usecases-P1'} />
          <HeroImage image={imgHero2x} />
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements elements={featuresA} />
            </Grid>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <Box mb={6}>
                <SectionTitle title={'Usecases-H5'} />
              </Box>
              <FeatureElements fourElements elements={featuresB} />
            </Grid>
            <Grid container className={classes.simpleFeatureContainer}>
              <Grid item xs={12} md={4}>
                <SectionTitle title="Usecases-H6" />
                <SectionContent text="Usecases-P6" />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={2} />
              </Hidden>
              <Grid item xs={12} md={6}>
                <Box mt={6}>
                  <UseCaseListItem content={'Usecases-P61'} />
                  <UseCaseListItem content={'Usecases-P65'} />
                  <UseCaseListItem content={'Usecases-P66'} />
                  <UseCaseListItem content={'Usecases-P68'} />
                  <UseCaseListItem content={'Usecases-P610'} />
                  <UseCaseListItem content={'Usecases-P69'} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
