import React, { FC } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import mihkel from '../../assets/team/Mihkel Board of Directors.png'
import beatrice from '../../assets/team/Beatrice Board of Directors.png'
import alena from '../../assets/team/Alena Board of Directors.png'
import Container from '@material-ui/core/Container'
import clsx from 'clsx'
import { TipPage } from '../Tips'
import { SubSubFooter } from '../Common/SubSubFooter'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'
import IconButton from '@material-ui/core/IconButton'
import LinkedIn from '../../assets/icons/linkedin.svg?react'
import { WebsiteTitle } from '../Common'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  row: {
    width: '100%',
    maxWidth: '1000px',
    margin: 'auto',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  pageTitle: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'top',
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  socials: {
    flexWrap: 'nowrap',
    '& .MuiIconButton-root': {
      marginTop: theme.spacing(-0.7),
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  greyText: {
    color: theme.palette.grey[600],
  },
  imgMaxHeight: {
    maxHeight: '400px',
  },
  titleSocialsBox: {
    display: 'flex',
    textAlign: 'center',
    [theme.breakpoints.only('sm')]: {
      display: 'block',
    },
  },
}))

export const BoardDirectorsPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  tagManagerHandler('PageBoardDirectors')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Directors'} />

          <Box className={classes.row}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12} md={10}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    className={classes.imgMaxHeight}
                    image={mihkel}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} className={classes.rowText}>
                <Box mb={5}>
                  <Typography variant={'h3'} className={classes.rowTitle}>
                    {t('Directors-Mihkel')}
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/in/mihkelv/"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Typography>
                  <Box className={classes.socials}>{/*</Box>*/}</Box>

                  <Typography className={classes.greyText} variant={'h4'}>
                    {t('Directors-Mihkel-Chairman')}
                  </Typography>
                </Box>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Directors-Mihkel-P1')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Directors-Mihkel-P2')}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container spacing={2}>
              <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
                <Box mb={5}>
                  <Typography variant={'h3'} className={classes.rowTitle}>
                    {t('Directors-Beatrice')}
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/in/beatrice-kern/"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Typography>
                  <Typography className={classes.greyText} variant={'h4'}>
                    {t('Directors-Beatrice-Vice')}
                  </Typography>
                </Box>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Directors-Beatrice-P1')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Directors-Beatrice-P2')}
                </Typography>
              </Grid>

              <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
                <Grid item xs={12} md={10}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    className={classes.imgMaxHeight}
                    image={beatrice}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12} md={10}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    className={classes.imgMaxHeight}
                    image={alena}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} className={classes.rowText}>
                <Box mb={5}>
                  <Typography variant={'h3'} className={classes.rowTitle}>
                    {t('Directors-Alena')}
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/in/alena-nicolai-gwerder-4589b540/"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Typography>
                  <Typography className={classes.greyText} variant={'h4'}>
                    {t('Directors-Alena-Member')}
                  </Typography>
                </Box>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Directors-Alena-P1')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Directors-Alena-P2')}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <TipPage title={t('Directors-Board')} emailAddress={'board@bivial.com'} />
          <SubSubFooter />
        </Container>
      </Box>
    </>
  )
}
