import React, { FC, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import { Hidden, makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { SectionTitle, SectionContent } from '../Common'

const useStyles = makeStyles((theme) => ({
  teaserContainer: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const DepositGuaranteedTeaser: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const signupHandler = useCallback(() => {
    window.location.href = 'https://dashboard.klarpay.com/signup'
  }, [history])

  return (
    <>
      <Box className={classes.teaserContainer}>
        <Hidden mdUp>
          <Box mt={12} />
        </Hidden>
        <SectionTitle title="Deposit-Guaranteed-Account-H2" />
        <SectionContent text="Deposit-Guaranteed-Account-P2" />
        <Box mt={4}>
          <Box mb={2}>
            <Typography>{t('Deposit-Guaranteed-Account-P21')}</Typography>
          </Box>
          <Box mb={2}>
            <Typography>{t('Deposit-Guaranteed-Account-P22')}</Typography>
          </Box>
          <Typography>{t('Deposit-Guaranteed-Account-P23')}</Typography>
        </Box>
        <Box mt={4} className={classes.buttonContainer}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={signupHandler}
            className={classes.getStartedButton}
          >
            {t('COMMON-CTA-GetStarted')}
          </Button>
        </Box>
      </Box>
    </>
  )
}
