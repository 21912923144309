import incomingMerchantPaymentsBankTransfersObj from './acceptPayments/accept_payments_bank_transfers'
import incomingMerchantPaymentsApmsObj from './acceptPayments/accept_payments_apms'
import incomingMerchantPaymentsCardsObj from './acceptPayments/accept_payments_cards'
import accountCurrenciesObj from './treasury/account_currencies'
import outgoingInternationalWiresObj from './sendPayments/send_payments_international_transfers'
import outgoingLocalAchObj from './sendPayments/send_payments_local_transfers'
import outboundWalletsInstantObj from './sendPayments/send_payments_alternative_methods'

const accountCurrencies = accountCurrenciesObj
const incomingMerchantPaymentsBankTransfers = incomingMerchantPaymentsBankTransfersObj
const incomingMerchantPaymentsApms = incomingMerchantPaymentsApmsObj
const incomingMerchantPaymentsCards = incomingMerchantPaymentsCardsObj
const outgoingInternationalWires = outgoingInternationalWiresObj
const outgoingLocalAch = outgoingLocalAchObj
const outboundWalletsInstant = outboundWalletsInstantObj

const exportedObject = {
  incomingMerchantPaymentsBankTransfers,
  incomingMerchantPaymentsApms,
  incomingMerchantPaymentsCards,
  accountCurrencies,
  outgoingInternationalWires,
  outgoingLocalAch,
  outboundWalletsInstant,
}

export default exportedObject
