import React, { FC } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import christos from '../../assets/team/Christos Executive Board.png'
import martynas from '../../assets/team/Martynas Executive Board.png'
import jeff from '../../assets/team/Jeff Executive Board.png'
import marc from '../../assets/team/Marc Executive Board.png'
import markus from '../../assets/team/Markus Executive Board.png'
import Container from '@material-ui/core/Container'
import clsx from 'clsx'
import { TipPage } from '../Tips'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'
import IconButton from '@material-ui/core/IconButton'
import LinkedIn from '../../assets/icons/linkedin.svg?react'
import { WebsiteTitle } from '../Common'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  row: {
    width: '100%',
    maxWidth: '1000px',
    margin: 'auto',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  pageTitle: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  greyText: {
    color: theme.palette.grey[600],
  },
  imgMaxHeight: {
    maxHeight: '400px',
  },
}))

export const ExecutiveManagementPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  tagManagerHandler('PageExecutiveManagement')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Management-Board'} />

          <Box className={classes.row}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12} md={10}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    className={classes.imgMaxHeight}
                    image={martynas}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} className={classes.rowText}>
                <Box mb={5}>
                  <Typography variant={'h3'} className={classes.rowTitle}>
                    {t('Management-Martynas')}
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/in/martynasb/"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Typography>
                  <Typography className={classes.greyText} variant={'h4'}>
                    {t('Management-Martynas-CEO')}
                  </Typography>
                </Box>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Martynas-P1')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Martynas-P2')}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container spacing={2}>
              <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
                <Box mb={5}>
                  <Typography variant={'h3'} className={classes.rowTitle}>
                    {t('Management-Markus')}
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/in/markus-em%C3%B6di-20399575/"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Typography>
                  <Typography className={classes.greyText} variant={'h4'}>
                    {t('Management-Markus-CLCO')}
                  </Typography>
                </Box>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Markus-P1')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Markus-P2')}
                </Typography>
              </Grid>

              <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
                <Grid item xs={12} md={10}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    className={classes.imgMaxHeight}
                    image={markus}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.row}>
            <Grid item xs={12} container justify={'center'}>
              <Typography
                component="h1"
                variant={'h1'}
                align="center"
                color="textPrimary"
                className={classes.pageTitle}
              >
                {t('Management-Team')}
              </Typography>
            </Grid>
          </Box>

          <Box className={classes.row}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12} md={10}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    className={classes.imgMaxHeight}
                    image={marc}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} className={classes.rowText}>
                <Box mb={5}>
                  <Typography variant={'h3'} className={classes.rowTitle}>
                    {t('Management-Marc')}
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/in/marc-evans-20333a7a/"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Typography>
                  <Typography className={classes.greyText} variant={'h4'}>
                    {t('Management-Marc-CFOO')}
                  </Typography>
                </Box>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Marc-P1')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Marc-P2')}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container spacing={2}>
              <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
                <Box mb={5}>
                  <Typography variant={'h3'} className={classes.rowTitle}>
                    {t('Management-Christos')}
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/in/alatzidis/"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Typography>
                  <Typography className={classes.greyText} variant={'h4'}>
                    {t('Management-Christos-CTO')}
                  </Typography>
                </Box>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Christos-P1')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Christos-P2')}
                </Typography>
              </Grid>

              <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
                <Grid item xs={12} md={10}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    className={classes.imgMaxHeight}
                    image={christos}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.row}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12} md={10}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    className={classes.imgMaxHeight}
                    image={jeff}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} className={classes.rowText}>
                <Box mb={5}>
                  <Typography variant={'h3'} className={classes.rowTitle}>
                    {t('Management-Jeff2')}
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/in/jeff-richard-angehrn-63b61366/"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Typography>
                  <Typography className={classes.greyText} variant={'h4'}>
                    {t('Management-Jeff2-Risk')}
                  </Typography>
                </Box>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Jeff2-P1')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('Management-Jeff2-P2')}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <TipPage title={t('Management-Executives')} emailAddress={'management@bivial.com'} />
        </Container>
      </Box>
    </>
  )
}
