import React, { FC, useCallback, useRef, useState } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import {
  Hidden,
  IconButton,
  List,
  MenuList,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core'
import MainMenuItem from './MainMenuItem'
import { MainSubMenuItem } from './MainSubMenuItem'
import {
  PATH_ABOUT,
  PATH_USE_CASES,
  PATH_BOARD_DIRECTORS,
  PATH_EXECUTIVE_MANAGEMENT,
  PATH_NEWS_AND_MEDIA,
  PATH_CARD_BIZ,
  PATH_CARD_PERSONAL,
  PATH_NEWS_ENTRIES,
  PATH_VIRTUAL_IBAN_ACCOUNTS,
  PATH_BUSINESS_ACCOUNT,
  PATH_MERCHANT_PAYMENT_ACCOUNT,
  PATH_DEPOSIT_GUARANTEED_ACCOUNT_BIZ,
  PATH_DEPOSIT_GUARANTEED_ACCOUNT_PERSONAL,
  PATH_CLIENT_FUNDS_ACCOUNT,
  PATH_CONNECTIVITY,
  PATH_ENTREPRENEUR_ACCOUNTS,
  // PATH_PRICING,
  PATH_PARTNERSHIPS,
  PATH_FAQ,
  PATH_TALKTOUS,
  PATH_PAYMENT_CAPABILITIES,
  PATH_PAYMENT_CAPABILITIES_INC_MER,
  PATH_PAYMENT_CAPABILITIES_INC_MER_APM,
  PATH_PAYMENT_CAPABILITIES_INC_MER_CAR,
  PATH_PAYMENT_CAPABILITIES_INC_MER_TRF,
  PATH_PAYMENT_CAPABILITIES_INC_TRX,
  PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR,
  PATH_PAYMENT_CAPABILITIES_OUT,
  // PATH_PAYMENT_CAPABILITIES_OUT_INS_BTR,
  PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR,
  PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH,
  PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL,
  // PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR,
  PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD,
} from '../../constants'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import MenuIconClose from '@material-ui/icons/Close'
import Logo from '../../assets/images/logo.svg?react'
import ArrowDown from '../../assets/icons/arrow-down.svg?react'

import { Login } from '../Login'
import { Account } from '../Account'
import { useTranslation } from 'react-i18next'

const drawerWidth = '100%'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiPaper-elevation4': {
        boxShadow: 'none',
      },
      Box: {
        padding: theme.spacing(2),
      },
    },
    '& div#root, body, html': {
      background: 'white',
    },
    tp: {
      padding: 0,
    },
    menuButton: {
      position: 'absolute',
      right: 16,
      top: 10,
    },
    title: {
      flexGrow: 1,
    },
    icon: {
      color: 'black',
    },
    accountIcon: {
      fontSize: '40px',
    },
    toolBar: {
      minHeight: '6rem',
      paddingLeft: theme.spacing(5),
    },
    mainMenu: {},

    fullList: {
      display: 'flex',
      width: 'auto',
      flexFlow: 'wrap',
    },
    aboutList: {
      width: 250,
    },
    drawerSize: {
      height: '205px',
    },
    description: {
      // opacity: '60%',
      fontWeight: 'normal',
      paddingTop: 12,
    },
    grid: {
      alignItem: 'center',
      margin: '55px 0 55px 0',
    },
    grid2: {
      padding: '10px 0 10px 0',
    },
    subMenuWrap: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      maxWidth: 1000,
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      border: 'none',
    },
    logoMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: 64,
      padding: '18px 30px 15px',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    LeftBlockMenu: { paddingTop: '20px !Important' },

    titleLeftBlockMenu: {
      fontWeight: 'bold',
    },
    mobMenuItemBold: {
      color: 'red',
    },
    emptyItemMenuItem: {
      opacity: 1,
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    emptyItemMenuItemBox: { textAlign: 'center', paddingTop: '8px', paddingBottom: '8px' },
    shortHeight: {
      height: '35px',
    },
    sectionTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: theme.spacing(3),
      marginLeft: theme.spacing(2.3),
      marginBottom: theme.spacing(1),
    },
  }),
)

const GridRowFirst: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Grid container spacing={1} className={classes.grid2}>
        {children}
      </Grid>
    </Grid>
  )
}

const GridColumnFirst: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid item md={4} xs={12}>
      <Grid container spacing={1} className={classes.grid2}>
        {children}
      </Grid>
    </Grid>
  )
}

const GridRowItem: FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={1} className={classes.grid2} alignItems={'flex-start'}>
      {children}
    </Grid>
  )
}

export const MainMenu: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)
  const [services, setServices] = useState<boolean>(false)
  const [company, setCompany] = useState<boolean>(false)
  const menuRef = useRef(null)

  const toggleDrawerMobile = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setMobileOpen(!mobileOpen)
  }

  const listServices = useCallback(() => {
    return (
      <Box className={classes.subMenuWrap}>
        <Grid container>
          <GridColumnFirst>
            <Grid item xs={12}>
              <GridRowItem>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <b>{t('MainMenu-P58')}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem primary={t('MainMenu-P32')} path={PATH_BUSINESS_ACCOUNT} />
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem
                    primary={t('MainMenu-P33')}
                    path={PATH_MERCHANT_PAYMENT_ACCOUNT}
                  />
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem
                    primary={t('MainMenu-P38')}
                    path={PATH_DEPOSIT_GUARANTEED_ACCOUNT_BIZ}
                  />
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem primary={t('MainMenu-P23')} path={PATH_CARD_BIZ} />
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem primary={t('MainMenu-P34')} path={PATH_CLIENT_FUNDS_ACCOUNT} />
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem primary={t('MainMenu-P26')} path={PATH_VIRTUAL_IBAN_ACCOUNTS} />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <b>{t('MainMenu-P59')}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem primary={t('MainMenu-P30')} path={PATH_ENTREPRENEUR_ACCOUNTS} />
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem
                    primary={t('MainMenu-P38')}
                    path={PATH_DEPOSIT_GUARANTEED_ACCOUNT_PERSONAL}
                  />
                </Grid>
                <Grid item xs={12} className={classes.shortHeight}>
                  <MainSubMenuItem primary={t('MainMenu-P23')} path={PATH_CARD_PERSONAL} />
                </Grid>
              </GridRowItem>
            </Grid>
          </GridColumnFirst>
          <GridColumnFirst>
            <GridRowItem>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle}>
                  <b>{t('MainMenu-P60')}</b>
                </Typography>
              </Grid>
              <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P63')}
                  path={PATH_PAYMENT_CAPABILITIES_INC_MER_TRF}
                />
              </Grid>
              <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P64')}
                  path={PATH_PAYMENT_CAPABILITIES_INC_MER_CAR}
                />
              </Grid>
              <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P65')}
                  path={PATH_PAYMENT_CAPABILITIES_INC_MER_APM}
                />
              </Grid>
              <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P66')}
                  path={PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD}
                />
              </Grid>
              <Grid className={classes.shortHeight} item xs={12} />
              <Grid item xs={12}>
                <MainSubMenuItem />
              </Grid>
            </GridRowItem>
            <GridRowItem>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle}>
                  <b>{t('MainMenu-P61')}</b>
                </Typography>
              </Grid>
              {/* <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P48')}
                  path={PATH_PAYMENT_CAPABILITIES_OUT_INS_BTR}
                />
              </Grid> */}
              <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P67')}
                  path={PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR}
                />
              </Grid>
              <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P71')}
                  path={PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH}
                />
              </Grid>
              <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P68')}
                  path={PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL}
                />
              </Grid>
              {/* <Grid className={classes.shortHeight} item xs={12}>
                <MainSubMenuItem
                  primary={t('MainMenu-P69')}
                  path={PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR}
                />
              </Grid> */}
            </GridRowItem>
          </GridColumnFirst>
          <GridColumnFirst>
            <GridRowItem>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle}>
                  <b>{t('MainMenu-P62')}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.shortHeight}>
                <MainSubMenuItem primary={t('MainMenu-P45')} path={PATH_CONNECTIVITY} />
              </Grid>
            </GridRowItem>
          </GridColumnFirst>
        </Grid>
      </Box>
    )
  }, [])

  const listCompany = useCallback(() => {
    return (
      <Box className={classes.subMenuWrap}>
        <Grid container>
          <GridRowFirst>
            <Grid item xs={12}>
              <GridRowItem>
                <Grid item xs={6}>
                  <Typography className={classes.sectionTitle}>
                    <b>{t('MainMenu-P73')}</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.sectionTitle}>
                    <b>{t('MainMenu-P56')}</b>
                  </Typography>
                </Grid>
                <Grid className={classes.shortHeight} item xs={6}>
                  <MainSubMenuItem primary={t('MainMenu-P54')} path={PATH_ABOUT} />
                </Grid>
                <Grid className={classes.shortHeight} item xs={6}>
                  <MainSubMenuItem primary={t('MainMenu-P12')} path={PATH_BOARD_DIRECTORS} />
                </Grid>
                <Grid className={classes.shortHeight} item xs={6}>
                  <MainSubMenuItem primary={t('MainMenu-P21')} path={PATH_NEWS_AND_MEDIA} />
                </Grid>
                <Grid className={classes.shortHeight} item xs={6}>
                  <MainSubMenuItem primary={t('MainMenu-P13')} path={PATH_EXECUTIVE_MANAGEMENT} />
                </Grid>
                <Grid className={classes.shortHeight} item xs={6}>
                  <MainSubMenuItem primary={t('MainMenu-P29')} path={PATH_FAQ} />
                </Grid>
                <Grid item xs={6}>
                  <MainSubMenuItem />
                </Grid>
              </GridRowItem>
            </Grid>
          </GridRowFirst>
        </Grid>
      </Box>
    )
  }, [])

  const drawer = (
    <div
      onClick={() => {
        setMobileOpen(false)
      }}
    >
      <Toolbar className={classes.logoMenu}>
        <Logo />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.menuButton}
          onClick={toggleDrawerMobile}
        >
          <MenuIconClose />
        </IconButton>
      </Toolbar>

      <List className={classes.mainMenu}>
        <MainMenuItem
          primary={t('MainMenu-P20')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem
          primary={t('MainMenu-P58')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P32')} path={PATH_BUSINESS_ACCOUNT} />
        <MainMenuItem primary={t('MainMenu-P33')} path={PATH_MERCHANT_PAYMENT_ACCOUNT} />
        <MainMenuItem primary={t('MainMenu-P38')} path={PATH_DEPOSIT_GUARANTEED_ACCOUNT_BIZ} />
        <MainMenuItem primary={t('MainMenu-P23')} path={PATH_CARD_BIZ} />
        <MainMenuItem primary={t('MainMenu-P34')} path={PATH_CLIENT_FUNDS_ACCOUNT} />
        <MainMenuItem primary={t('MainMenu-P26')} path={PATH_VIRTUAL_IBAN_ACCOUNTS} />
        <MainMenuItem
          primary={t('MainMenu-P59')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P30')} path={PATH_ENTREPRENEUR_ACCOUNTS} />
        <MainMenuItem primary={t('MainMenu-P38')} path={PATH_DEPOSIT_GUARANTEED_ACCOUNT_PERSONAL} />
        <MainMenuItem primary={t('MainMenu-P23')} path={PATH_CARD_PERSONAL} />
        <MainMenuItem
          primary={t('MainMenu-P60')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P63')} path={PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR} />
        <MainMenuItem primary={t('MainMenu-P64')} path={PATH_PAYMENT_CAPABILITIES_INC_MER_CAR} />
        <MainMenuItem primary={t('MainMenu-P65')} path={PATH_PAYMENT_CAPABILITIES_INC_MER_APM} />
        <MainMenuItem primary={t('MainMenu-P66')} path={PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD} />
        <MainMenuItem
          primary={t('MainMenu-P61')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        {/* <MainMenuItem primary={t('MainMenu-P48')} path={PATH_PAYMENT_CAPABILITIES_OUT_INS_BTR} /> */}
        <MainMenuItem primary={t('MainMenu-P67')} path={PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR} />
        <MainMenuItem primary={t('MainMenu-P71')} path={PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH} />
        <MainMenuItem primary={t('MainMenu-P68')} path={PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL} />
        {/* <MainMenuItem primary={t('MainMenu-P69')} path={PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR} /> */}
        <MainMenuItem
          primary={t('MainMenu-P62')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P45')} path={PATH_CONNECTIVITY} />
        <MainMenuItem
          primary={t('MainMenu-P15')}
          path={PATH_USE_CASES}
          className={classes.mobMenuItemBold}
        />
        {/* <MainMenuItem
          primary={t('MainMenu-P31')}
          path={PATH_PRICING}
          className={classes.mobMenuItemBold}
        /> */}

        <MainMenuItem
          primary={t('MainMenu-P28')}
          path={PATH_PARTNERSHIPS}
          className={classes.mobMenuItemBold}
        />

        <MainMenuItem
          primary={t('MainMenu-P16')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem
          primary={t('MainMenu-P73')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P54')} path={PATH_ABOUT} />
        <MainMenuItem primary={t('MainMenu-P21')} path={PATH_NEWS_AND_MEDIA} />
        <MainMenuItem primary={t('MainMenu-P29')} path={PATH_FAQ} />
        <MainMenuItem
          primary={t('MainMenu-P56')}
          path={undefined}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P12')} path={PATH_BOARD_DIRECTORS} />
        <MainMenuItem primary={t('MainMenu-P13')} path={PATH_EXECUTIVE_MANAGEMENT} />
        <MainMenuItem
          primary={t('TalkToUs')}
          path={PATH_TALKTOUS}
          className={classes.mobMenuItemBold}
        />
      </List>
      <Box m={1}>
        <Grid container spacing={1} direction={'column'}>
          <Grid item>
            <Box textAlign={'center'}>
              {/* <LanguageSwitch /> */}
              <Login />
            </Box>
          </Grid>
          <Grid item>
            <Account />
          </Grid>
        </Grid>
      </Box>
    </div>
  )

  return (
    <>
      <Grid container spacing={1} justify={'center'}>
        <Grid item xs={12} lg={10} xl={6}>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              className={classes.menuButton}
              onClick={toggleDrawerMobile}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={toggleDrawerMobile}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <MenuList ref={menuRef} className={classes.tp}>
              <MainMenuItem
                icon={<ArrowDown />}
                primary={t('MainMenu-P20')}
                forceHover={!!services}
                path={[
                  PATH_BUSINESS_ACCOUNT,
                  PATH_MERCHANT_PAYMENT_ACCOUNT,
                  PATH_CLIENT_FUNDS_ACCOUNT,
                  PATH_DEPOSIT_GUARANTEED_ACCOUNT_BIZ,
                  PATH_VIRTUAL_IBAN_ACCOUNTS,
                  PATH_CARD_BIZ,
                  PATH_ENTREPRENEUR_ACCOUNTS,
                  PATH_CONNECTIVITY,
                  PATH_PAYMENT_CAPABILITIES,
                  PATH_PAYMENT_CAPABILITIES_INC_MER,
                  PATH_PAYMENT_CAPABILITIES_INC_MER_APM,
                  PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD,
                  PATH_PAYMENT_CAPABILITIES_INC_MER_CAR,
                  PATH_PAYMENT_CAPABILITIES_INC_TRX,
                  PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR,
                  PATH_PAYMENT_CAPABILITIES_OUT,
                  // PATH_PAYMENT_CAPABILITIES_OUT_INS_BTR,
                  PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR,
                  PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH,
                  PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL,
                  // PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR,
                ]}
                setState={setServices}
              />
              <MainMenuItem primary={t('MainMenu-P15')} path={PATH_USE_CASES} />
              {/* <MainMenuItem primary={t('MainMenu-P31')} path={PATH_PRICING} /> */}
              <MainMenuItem primary={t('MainMenu-P28')} path={PATH_PARTNERSHIPS} />
              <MainMenuItem
                icon={<ArrowDown />}
                primary={t('MainMenu-P16')}
                forceHover={!!company}
                path={[
                  PATH_ABOUT,
                  PATH_NEWS_AND_MEDIA,
                  PATH_FAQ,
                  PATH_BOARD_DIRECTORS,
                  PATH_EXECUTIVE_MANAGEMENT,
                  PATH_NEWS_ENTRIES,
                ]}
                setState={setCompany}
              />
              <MainMenuItem primary={t('TalkToUs')} path={PATH_TALKTOUS} />
            </MenuList>

            <Menu
              id={'simple-menu1'}
              anchorEl={menuRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  marginTop: '60px', // Add 20 pixels below the anchor element
                  paddingBottom: '20px',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Customize the drop shadow
                },
              }}
              open={services}
              onClick={() => setServices(false)}
            >
              {listServices()}
            </Menu>
            <Menu
              id={'simple-menu5'}
              anchorEl={menuRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  marginTop: '60px', // Add 20 pixels below the anchor element
                  paddingBottom: '20px',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Customize the drop shadow
                  width: '806px',
                },
              }}
              open={company}
              onClick={() => setCompany(false)}
            >
              {listCompany()}
            </Menu>
          </Hidden>
        </Grid>
      </Grid>
    </>
  )
}
