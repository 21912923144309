import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  row: {
    maxWidth: '1000px',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
}))

export const HeroImage: FC<{
  image: string
  subtitle?: string
}> = ({ image }) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.row}>
        <Grid container>
          <Grid item xs={12}>
            <CardMedia component="img" alt="" height="100%" image={image} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
