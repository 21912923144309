import { FC, default as React } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import IconAngledArrow from '../../assets/icons/icon-angled-right-arrow.svg?react'
import { Hidden, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { tagManagerHandler } from '../../utils'
import corresondentBanks from './banks'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  greyBox: {
    backgroundColor: '#fafafa',
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  pageTitle: {
    fontSize: '2.75rem',
    marginTop: theme.spacing(6),
    width: '100%',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& .MuiTableCell-body': {
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
  },
  header: {
    opacity: 1,
  },
  arrow: {
    width: '24px',
    marginLeft: theme.spacing(1),
  },
}))

export const CorrespondentBanksPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  tagManagerHandler('PageCorrespondentBanks')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <Typography
            component="h1"
            variant={'h1'}
            align="center"
            color="textPrimary"
            gutterBottom
            className={classes.pageTitle}
          >
            {t('CorrespondentBanks-H1')}
            <br />
            {t('CorrespondentBanks-H2')}
          </Typography>
          <Grid container className={classes.featureContainer}>
            <Container>
              <Box className={clsx(classes.row, classes.greyBox)}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ minHeight: '120px' }}
                >
                  <Grid item md={3} xs={12}>
                    <Typography>
                      <div dangerouslySetInnerHTML={{ __html: t('CorrespondentBanks-P1') }} />
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography>
                      <div dangerouslySetInnerHTML={{ __html: t('CorrespondentBanks-P2') }} />
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography>
                      <div dangerouslySetInnerHTML={{ __html: t('CorrespondentBanks-P3') }} />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Hidden smDown>
                <Box className={classes.row}>
                  <Grid item xs={12} container style={{ margin: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {Array.from(Array(6), (e, i) => (
                            <TableCell className={classes.header} key={i}>
                              <b>{t(`CorrespondentBanks-Table-${i + 1}`)}</b>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {corresondentBanks.map((bank: any, i: number) => (
                          <>
                            <TableRow
                              key={`bank-${i}`}
                              style={{ backgroundColor: i % 2 === 0 ? '#eee' : '' }}
                            >
                              <TableCell>{bank.currency}</TableCell>
                              <TableCell>{bank.bic}</TableCell>
                              <TableCell>{bank.name}</TableCell>
                              <TableCell>{bank.account}</TableCell>
                              <TableCell>{bank.id}</TableCell>
                              <TableCell>{bank.country}</TableCell>
                            </TableRow>
                            {bank.correspondent && (
                              <TableRow style={{ backgroundColor: i % 2 === 0 ? '#eee' : '' }}>
                                <TableCell>
                                  <IconAngledArrow className={classes.arrow} />
                                </TableCell>
                                <TableCell>
                                  <b>{t(`CorrespondentBanks-Table-8`)}</b>
                                </TableCell>
                                <TableCell colSpan={4}>{bank.correspondent}</TableCell>
                              </TableRow>
                            )}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Box>
              </Hidden>

              <Hidden mdUp>
                <Box className={classes.row}>
                  <Grid item xs={12} container style={{ margin: 'auto' }}>
                    {corresondentBanks.map((bank: any, i: number) => [
                      <Table
                        className={classes.row}
                        style={{ backgroundColor: i % 2 === 0 ? '#eee' : '' }}
                        key={`bank-${i}`}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <b>{t(`CorrespondentBanks-Table-1`)}</b>
                            </TableCell>
                            <TableCell>{bank.currency}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>{t(`CorrespondentBanks-Table-2`)}</b>
                            </TableCell>
                            <TableCell>{bank.bic}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>{t(`CorrespondentBanks-Table-3`)}</b>
                            </TableCell>
                            <TableCell>{bank.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>{t(`CorrespondentBanks-Table-4`)}</b>
                            </TableCell>
                            <TableCell>{bank.account}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>{t(`CorrespondentBanks-Table-5`)}</b>
                            </TableCell>
                            <TableCell>{bank.id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>{t(`CorrespondentBanks-Table-6`)}</b>
                            </TableCell>
                            <TableCell>{bank.country}</TableCell>
                          </TableRow>
                          {bank.correspondent && (
                            <TableRow>
                              <TableCell>
                                <b>{t(`CorrespondentBanks-Table-8`)}</b>
                              </TableCell>
                              <TableCell colSpan={4}>{bank.correspondent}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>,
                    ])}
                  </Grid>
                </Box>
              </Hidden>
            </Container>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
