export const PATH_ROOT = '/'
export const PATH_HOME = '/home'
export const PATH_USE_CASES = '/use-cases'
export const PATH_MARKET_PLACES = '/marketplaces'
export const PATH_CARD = '/cards/business'
export const PATH_CARD_BIZ = '/cards/business'
export const PATH_CARD_PERSONAL = '/cards/personal'
export const PATH_APPLE_PAY = '/apple-pay'
export const PATH_GOOGLE_PAY = '/google-pay'
export const PATH_VIRTUAL_IBAN_ACCOUNTS = '/virtual-iban-accounts'
export const PATH_BUSINESS_ACCOUNT = '/business-accounts'
export const PATH_PAYMENT_CAPABILITIES = '/payment-capabilities'
export const PATH_PAYMENT_CAPABILITIES_INC_MER = '/payment-capabilities/accept-payments'
export const PATH_PAYMENT_CAPABILITIES_INC_MER_TRF =
  '/payment-capabilities/accept-payments/bank-transfers'
export const PATH_PAYMENT_CAPABILITIES_INC_MER_APM = '/payment-capabilities/accept-payments/apms'
export const PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD =
  '/payment-capabilities/accept-payments/apms/direct-debit'
export const PATH_PAYMENT_CAPABILITIES_INC_MER_CAR = '/payment-capabilities/accept-payments/cards'
export const PATH_PAYMENT_CAPABILITIES_INC_TRX = '/payment-capabilities/treasury'
export const PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR =
  '/payment-capabilities/treasury/account-currencies'
export const PATH_PAYMENT_CAPABILITIES_OUT = '/payment-capabilities/send-payments'
// export const PATH_PAYMENT_CAPABILITIES_OUT_INS_BTR =
//   '/payment-capabilities/send-payments/instant-bank-transfers'
export const PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR =
  '/payment-capabilities/send-payments/international-payouts'
export const PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH =
  '/payment-capabilities/send-payments/local-payouts'
export const PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL =
  '/payment-capabilities/send-payments/local-alternative-payouts'
export const PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR =
  '/payment-capabilities/send-payments/push-to-card'
export const PATH_PAYMENT_CAPABILITIES_CATEGORY = '/payment-capabilities/:category'
export const PATH_PAYMENT_CAPABILITIES_CATEGORY_SUBCATEGORY =
  '/payment-capabilities/:category/:subcategory'
export const PATH_MERCHANT_PAYMENT_ACCOUNT = '/merchant-payment-accounts'
export const PATH_CLIENT_FUNDS_ACCOUNT = '/client-funds-accounts'
export const PATH_DEPOSIT_GUARANTEED_ACCOUNT = '/deposit-guaranteed-accounts'
export const PATH_DEPOSIT_GUARANTEED_ACCOUNT_BIZ = '/deposit-guaranteed-accounts/business'
export const PATH_DEPOSIT_GUARANTEED_ACCOUNT_PERSONAL = '/deposit-guaranteed-accounts/personal'
export const PATH_CONNECTIVITY = '/connectivity'
export const PATH_ENTREPRENEUR_ACCOUNTS = '/entrepreneur-accounts'
export const PATH_ABOUT = '/about'
export const PATH_KLARPAY = '/klarpay'
export const PATH_BOARD_DIRECTORS = '/board-directors'
export const PATH_EXECUTIVE_MANAGEMENT = '/management'
export const PATH_NEWS_AND_MEDIA = '/news'
export const PATH_NEWS_PAGES = '/news/pages'
export const PATH_NEWS_PAGES_CATEGORY = '/news/pages/:category'
export const PATH_NEWS_PAGES_CATEGORY_PAGES = '/news/pages/:category/:page'
export const PATH_NEWS_ENTRIES = '/news/:id'
export const PATH_FAQ = '/faq'
export const PATH_TALKTOUS = '/talk-to-us'
export const PATH_PRICING = '/pricing'
export const PATH_PRICING_PAGES_CATEGORY = '/pricing/:category'
export const PATH_PARTNERSHIPS = '/partnerships'
export const PATH_IMPRESSUM = '/impressum'
export const PATH_IMPRINT = '/imprint'
export const PATH_CORRESPONDENT_BANKS = '/correspondent-banks'
export const PATH_ACCEPTED_COUNTRIES = '/accepted-countries'
export const PATH_PRIVACY_POLICY = '/privacy'
export const PATH_DATA_PROCESSORS = '/data-processors'
export const PATH_SIGN_UP = '/signup'
export const PATH_LOG_IN = '/login'
export const PATH_MESSAGE_SENT = '/message-sent'
export const PATH_404 = '/not-found'

export const OLD_PATH_MEDIA_INFLUENCERS = '/media-influencers'
export const OLD_PATH_AFFILIATE_NETWORKS = '/affiliate-networks'
export const OLD_PATH_ECOMMERCE = '/online-merchants'
export const OLD_PATH_FINTECHS = '/fintechs'
export const OLD_PATH_CROSS_BORDER_PAYMENTS = '/cross-border-payments'
export const OLD_PATH_API = '/api'
export const OLD_PATH_KLARPAY = '/klarpay'
export const OLD_PATH_ADVISORS = '/advisors'
export const OLD_PATH_OVERVIEW = '/overview'
