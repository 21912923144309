import React, { FC } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import ugne from '../../assets/team/Ugne.png'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'
import { SubSubFooter } from '../Common/SubSubFooter'
import IconButton from '@material-ui/core/IconButton'
import LinkedIn from '../../assets/icons/linkedin.svg?react'

const useStyles = makeStyles((theme) => ({
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  row: {
    width: '100%',
    maxWidth: '1000px',
    margin: 'auto',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
  pageTitle: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'top',
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  greyText: {
    color: theme.palette.grey[600],
  },
  imgMaxHeight: {
    maxHeight: '400px',
  },
}))

export const AdvisorsPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  tagManagerHandler('PageExecutiveManagement')

  return (
    <>
      <Container>
        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              className={classes.pageTitle}
            >
              {t('Advisors')}
            </Typography>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.extraMargin)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} md={10}>
                <CardMedia
                  component="img"
                  alt=""
                  height="100%"
                  className={classes.imgMaxHeight}
                  image={ugne}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.rowText}>
              <Box mb={5}>
                <Typography variant={'h3'} className={classes.rowTitle}>
                  {t('Advisors-Ugne')}
                  <IconButton
                    aria-label="LinkedIn"
                    target="_blank"
                    href="https://www.linkedin.com/in/ugn%C4%97-b-5a748927/"
                  >
                    <LinkedIn />
                  </IconButton>
                </Typography>
                <Typography className={classes.greyText} variant={'h4'}>
                  {t('Advisors-Ugne-CEO')}
                </Typography>
              </Box>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Advisors-Ugne-P1')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Advisors-Ugne-P2')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <SubSubFooter />
      </Container>
    </>
  )
}
