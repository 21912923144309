import { FC, default as React, useCallback } from 'react'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Button, Hidden } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  WebsiteTitle,
  MediaObject,
  FeatureElements,
  FeatureIcons,
  SectionTitle,
  SubSubFooter,
  SectionContent,
} from '../Common'
import { EntrepreneurAccountsTeaser } from './EntrepreneurAccountsTeaser'
import { tagManagerHandler } from '../../utils'
import laptop from '../../assets/images/img-entrepreneur-accounts-laptop@2x.png'
import icon1 from '../../assets/icons/img-entrepreneur-accounts-no-hidden-fees.svg'
import icon2 from '../../assets/icons/img-entrepreneur-accounts-3d-secure.svg'
import icon3 from '../../assets/icons/img-entrepreneur-accounts-2fa.svg'
import icon4 from '../../assets/icons/img-entrepreneur-accounts-dedicated-support.svg'
import icon11 from '../../assets/icons/img-entrepreneur-accounts-11.svg'
import icon12 from '../../assets/icons/img-entrepreneur-accounts-12.svg'
import icon13 from '../../assets/icons/img-entrepreneur-accounts-13.svg'
import icon14 from '../../assets/icons/img-entrepreneur-accounts-14.svg'
import icon15 from '../../assets/icons/img-entrepreneur-accounts-15.svg'
import icon16 from '../../assets/icons/img-entrepreneur-accounts-16.svg'
import appleGoogle from '../../assets/images/img-entrepreneur-account-apple-google@2x.png'
import { PATH_CARD } from '../../constants'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  centerSection: {
    maxWidth: '700px',
    margin: '-30px auto 30px',
    '& > p': {
      textAlign: 'center',
    },
    '& > div > h2': {
      textAlign: 'center',
    },
  },
  icon: {
    height: '24px',
    width: 'auto',
  },
  iconLarge: {
    height: '36px',
    width: 'auto',
    marginBottom: theme.spacing(4),
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  iconFeatureContainer: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  button: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

type SimpleFeatureElement = { icon: string; title: string; content: string }
type IconFeatureElement = { title: string; icon: string }

const features: SimpleFeatureElement[] = [
  {
    icon: icon11,
    title: 'EntrepreneurAccounts-H4',
    content: 'EntrepreneurAccounts-P4',
  },
  {
    icon: icon12,
    title: 'EntrepreneurAccounts-H5',
    content: 'EntrepreneurAccounts-P5',
  },
  {
    icon: icon13,
    title: 'EntrepreneurAccounts-H6',
    content: 'EntrepreneurAccounts-P6',
  },
  {
    icon: icon14,
    title: 'EntrepreneurAccounts-H7',
    content: 'EntrepreneurAccounts-P7',
  },
  {
    icon: icon15,
    title: 'EntrepreneurAccounts-H8',
    content: 'EntrepreneurAccounts-P8',
  },
  {
    icon: icon16,
    title: 'EntrepreneurAccounts-H9',
    content: 'EntrepreneurAccounts-P9',
  },
]

const icons: IconFeatureElement[] = [
  {
    title: 'EntrepreneurAccounts-P111',
    icon: icon1,
  },
  {
    title: 'EntrepreneurAccounts-P113',
    icon: icon2,
  },
  {
    title: 'EntrepreneurAccounts-P114',
    icon: icon3,
  },
  {
    title: 'EntrepreneurAccounts-P112',
    icon: icon4,
  },
]

export const EntrepreneurAccountsPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  tagManagerHandler('PageCard')

  const clickHandlerCard = useCallback(() => {
    history.push(PATH_CARD)
  }, [history])

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'EntrepreneurAccounts-H1'} />
          <Grid container className={classes.featureContainer}>
            <Grid container>
              <MediaObject imagePlacement={'right'} image={laptop} imageSize={'large'}>
                <EntrepreneurAccountsTeaser />
              </MediaObject>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.centerSection}>
                <SectionTitle title="EntrepreneurAccounts-H3" danger />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements elements={features} />
            </Grid>
          </Grid>
          <Grid container className={classes.featureContainer}>
            <Grid container className={classes.mediaObjectContainer}>
              <MediaObject
                imagePlacement={'right'}
                image={appleGoogle}
                imageSize={'large'}
                fullHeight={false}
              >
                <Box pl={3}>
                  <Hidden mdUp>
                    <Box mt={12} />
                  </Hidden>
                  <SectionTitle title={'EntrepreneurAccounts-H10'} />
                  <SectionContent text={'EntrepreneurAccounts-P10'} danger />
                  <Box mt={4} mb={6} className={classes.buttonContainer}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={clickHandlerCard}
                      className={classes.button}
                    >
                      {t('EntrepreneurAccounts-CTA-Get-Started')}
                    </Button>
                  </Box>
                </Box>
              </MediaObject>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.iconFeatureContainer}>
              <FeatureIcons elements={icons} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
