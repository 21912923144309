import imgNews1 from '../../../assets/images/news/news-fintech-license.png'
import imgNews2 from '../../../assets/images/news/news-marc-evans-bivial-coo.png'
import imgNews3 from '../../../assets/images/news/news-bivial-raises-3mchf.png'
import imgNews4 from '../../../assets/images/news/news-bivial-visa.png'
import imgNews5 from '../../../assets/images/news/news-apple-pay-bivial-cards.png'
import imgNews6 from '../../../assets/images/news/news-benefits-apple-pay.png'
import imgNews7 from '../../../assets/images/news/news-google-pay-bivial-cards.png'
import imgNews8 from '../../../assets/images/news/news-google-pay-benefits.png'
import imgNews9 from '../../../assets/images/news/news-bivial-global-transactions.png'
import imgNews10 from '../../../assets/images/news/news-bivial-sponsors-webmaster-conference-2022.png'
import imgNews11 from '../../../assets/images/news/news-webmaster-2022.png'
import imgNews12 from '../../../assets/images/news/news-bivial-joins-dukascopy-discussion-panel-2022.png'
import imgNews13 from '../../../assets/images/news/news-bivial-eurofinance-2022.png'
import imgNews14 from '../../../assets/images/news/news-bivial-swiss-iban-accounts.png'
import imgNews16 from '../../../assets/images/news/news-bivial-q3-2022.png'
import imgNews17 from '../../../assets/images/news/news-reflect-festival-2022-klarpay.png'
import imgNews18 from '../../../assets/images/news/news-bivial-reflect-festival-2022.png'
import imgNews19 from '../../../assets/images/news/news-bivial-sigma-event-2022.png'
import imgNews20 from '../../../assets/images/news/news-bivial-international-payments-usd-gbp-accounts.png'
import imgNews21 from '../../../assets/images/news/news-bivial-api.png'
import imgNews22 from '../../../assets/images/news/news-bivial-aws.png'
import imgNews23 from '../../../assets/images/news/news-bivial-q4-2022.png'
import imgNews24 from '../../../assets/images/news/news-bivial-ice-london-2023.png'
import imgNews25 from '../../../assets/images/news/news-bivial-13-new-currencies-accounts.png'
import imgNews27 from '../../../assets/images/news/news-bivial-affiliate-world-dubai-2023.png'
import imgNews28 from '../../../assets/images/news/news-bivial-profit-first-year-operations.png'
import imgNews29 from '../../../assets/images/news/news-fintech-banking.png'
import imgNews30 from '../../../assets/images/news/news-nextgen-payments-regtech-forum-2023.png'
import imgNews31 from '../../../assets/images/news/news-bivial-q1-2023.png'
import imgNews32 from '../../../assets/images/news/news-seamless-middle-east-2023.png'
import imgNews33 from '../../../assets/images/news/news-money2020-amsterdam-2023.png'
import imgNews34 from '../../../assets/images/news/news-mena-gaming-esports-summit.png'
import imgNews35 from '../../../assets/images/news/news-ach-payments-40-currencies.png'
import imgNews36 from '../../../assets/images/news/news-affiliate-world-barcelona-2023.png'
import imgNews37 from '../../../assets/images/news/news-bivial-swiss-youth-olympic-sailing.png'
import imgNews38 from '../../../assets/images/news/news-bivial-q2-2023.png'
import imgNews39 from '../../../assets/images/news/news-bivial-usd-visa-debit-cards.png'
import imgNews40 from '../../../assets/images/news/news-marcus-emoedi-bivial-clco.png'
import imgNews41 from '../../../assets/images/news/news-wn-conference-2023.png'
import imgNews42 from '../../../assets/images/news/news-swiss-fintech-fair.png'
import imgNews43 from '../../../assets/images/news/news-bivial-sibos-annual-conference-2023.png'
import imgNews44 from '../../../assets/images/news/news-bivial-reflect-festival-2023.png'
import imgNews45 from '../../../assets/images/news/news-bivial-q3-2023.png'
import imgNews46 from '../../../assets/images/news/news-websummit-2023.png'
import imgNews47 from '../../../assets/images/news/news-bivial-ifx-expo-2024-dubai.png'
import imgNews48 from '../../../assets/images/news/news-bivial-q4-2023.png'
import imgNews49 from '../../../assets/images/news/news-bivial-aw-2024-dubai.png'
import imgNews50 from '../../../assets/images/news/news-entrepreneur-accounts-klarpay.png'
import imgNews51 from '../../../assets/images/news/news-swiss-sailing-sponsorship-accounts-klarpay.png'
import imgNews52 from '../../../assets/images/news/news-bivial-reflect-festival-2024.png'
import imgNews53 from '../../../assets/images/news/news-paytech-awards-bivial-2024.png'
import imgNews54 from '../../../assets/images/news/news-money2020-2024.png'
import imgNews55 from '../../../assets/images/news/news-ifx-expo-international-2024.png'
import imgNews56 from '../../../assets/images/news/news-bivial-q1-q2-2024.png'
import imgNews57 from '../../../assets/images/news/news-bivial-awbudapest-2024.png'
import imgNews58 from '../../../assets/images/news/news-bivial-ifxasia-2024.png'
import imgNews59 from '../../../assets/images/news/news-websummit-24@2x.png'
import imgNews60 from '../../../assets/images/news/news-finextra@2x.png'
import imgNews61 from '../../../assets/images/news/news-ifx-dubai-25@2x.png'
import imgNews62 from '../../../assets/images/news/ice-25@2x.png'
import imgNews63 from '../../../assets/images/news/news-klarpay-to-bivial.png'
import imgNews64 from '../../../assets/images/news/news-bivial-tes-2025.png'

export enum NewsType {
  Latest = 'Latest',
  News = 'Company News',
  Article = 'Articles',
  Events = 'Events',
  Press = 'In the Press',
}
// Always put new entries in the beginning of the array
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    type: NewsType.Events,
    permalink: 'bivial-at-tes-seville-2025-seville-spain',
    publishDate: '2025-03-09',
    hidden: false,
    image: imgNews64,
    title: 'News-Article-64-Title',
    date: 'News-Article-64-Date',
    place: 'News-Article-64-Place',
    summary: 'News-Article-64-S',
    link: 'https://calendly.com/kristinelukina/tes-affiliate-conference?back=1&month=2025-03',
    texts: ['News-Article-64-P1', 'News-Article-64-P2', 'News-Article-64-P3'],
  },
  {
    type: NewsType.Events,
    permalink: 'join-bivial-at-ice-barcelona-2025',
    publishDate: '2025-02-11',
    hidden: false,
    image: imgNews62,
    title: 'News-Article-62-Title',
    date: 'News-Article-62-Date',
    place: 'News-Article-62-Place',
    summary: 'News-Article-62-S',
    link: 'https://calendly.com/georgio-bivial/ice-barcelona-2025',
    texts: ['News-Article-62-P1', 'News-Article-62-P2', 'News-Article-62-P3'],
  },
  {
    type: NewsType.Events,
    permalink: 'bivial-to-attend-ifx-expo-dubai-2025',
    publishDate: '2024-12-10',
    hidden: false,
    image: imgNews61,
    title: 'News-Article-61-Title',
    date: 'News-Article-61-Date',
    place: 'News-Article-61-Place',
    summary: 'News-Article-61-S',
    link: 'https://calendly.com/svetlana-6zac/ifx-expo-dubai-2025?month=2025-01',
    texts: ['News-Article-61-P1', 'News-Article-61-P2', 'News-Article-61-P3'],
  },
  {
    type: NewsType.News,
    permalink:
      'klarpay-ag-to-rebrand-as-bivial-ag-expanding-vision-for-holistic-banking-solutions-regulatory-growth',
    publishDate: '2024-11-13',
    hidden: false,
    image: imgNews63,
    title: 'News-Article-63-Title',
    date: 'News-Article-63-Date',
    place: 'News-Article-63-Place',
    summary: 'News-Article-63-S',
    texts: ['News-Article-63-P1', 'News-Article-63-P2', 'News-Article-63-P3', 'News-Article-63-P4'],
  },
  {
    type: NewsType.Article,
    permalink: 'not-so-open-banking-a-missed-opportunity-for-ambitious-businesses',
    publishDate: '2024-10-17',
    hidden: false,
    image: imgNews60,
    title: 'News-Article-60-Title',
    date: 'News-Article-60-Date',
    place: 'News-Article-60-Place',
    summary: 'News-Article-60-S',
    texts: ['News-Article-60-P1', 'News-Article-60-P2', 'News-Article-60-P3'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-joins-web-summit-2024-in-lisbon',
    publishDate: '2024-08-19',
    hidden: false,
    image: imgNews59,
    title: 'News-Article-59-Title',
    date: 'News-Article-59-Date',
    place: 'News-Article-59-Place',
    summary: 'News-Article-59-S',
    texts: [
      'News-Article-59-P1',
      'News-Article-59-P2',
      'News-Article-59-P3',
      'News-Article-59-P4',
      'News-Article-59-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-to-attend-ifx-expo-asia-2024-in-bangkok',
    publishDate: '2024-08-19',
    hidden: false,
    image: imgNews58,
    title: 'News-Article-58-Title',
    date: 'News-Article-58-Date',
    place: 'News-Article-58-Place',
    summary: 'News-Article-58-S',
    texts: [
      'News-Article-58-P1',
      'News-Article-58-P2',
      'News-Article-58-P3',
      'News-Article-58-P4',
      'News-Article-58-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-joins-affiliate-world-budapest-2024',
    publishDate: '2024-07-26',
    hidden: false,
    image: imgNews57,
    title: 'News-Article-57-Title',
    date: 'News-Article-57-Date',
    place: 'News-Article-57-Place',
    summary: 'News-Article-57-S',
    texts: [
      'News-Article-57-P1',
      'News-Article-57-P2',
      'News-Article-57-P3',
      'News-Article-57-P4',
      'News-Article-57-P5',
    ],
  },
  {
    type: NewsType.Article,
    permalink: 'klarpay-q1-q2-in-review-2024',
    publishDate: '2024-07-15',
    hidden: false,
    image: imgNews56,
    title: 'News-Article-56-Title',
    date: 'News-Article-56-Date',
    place: 'News-Article-56-Place',
    summary: 'News-Article-56-S',
    texts: [
      'News-Article-56-P1',
      'News-Article-56-P2',
      'News-Article-56-P3',
      'News-Article-56-P4',
      'News-Article-56-P5',
      'News-Article-56-P6',
      'News-Article-56-P7',
      'News-Article-56-P8',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-heads-to-ifx-expo-international-2024',
    publishDate: '2024-06-04',
    hidden: false,
    image: imgNews55,
    title: 'News-Article-55-Title',
    date: 'News-Article-55-Date',
    place: 'News-Article-55-Place',
    summary: 'News-Article-55-S',
    texts: ['News-Article-55-P1', 'News-Article-55-P2', 'News-Article-55-P3', 'News-Article-55-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-joins-money-2020',
    publishDate: '2024-05-15',
    hidden: false,
    image: imgNews54,
    title: 'News-Article-54-Title',
    date: 'News-Article-54-Date',
    place: 'News-Article-54-Place',
    summary: 'News-Article-54-S',
    texts: [
      'News-Article-54-P1',
      'News-Article-54-P2',
      'News-Article-54-P3',
      'News-Article-54-P4',
      'News-Article-54-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-nominated-for-best-new-payments-brand-award',
    publishDate: '2024-05-14',
    hidden: false,
    image: imgNews53,
    title: 'News-Article-53-Title',
    date: 'News-Article-53-Date',
    place: 'News-Article-53-Place',
    summary: 'News-Article-53-S',
    texts: [
      'News-Article-53-P1',
      'News-Article-53-P2',
      'News-Article-53-P3',
      'News-Article-53-P4',
      'News-Article-53-P5',
      'News-Article-53-P6',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-goes-where-doers-unite',
    publishDate: '2024-05-08',
    hidden: false,
    image: imgNews52,
    title: 'News-Article-52-Title',
    date: 'News-Article-52-Date',
    place: 'News-Article-52-Place',
    summary: 'News-Article-52-S',
    texts: [
      'News-Article-52-P1',
      'News-Article-52-P2',
      'News-Article-52-P3',
      'News-Article-52-P4',
      'News-Article-52-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-renews-commitment-to-swiss-youth-olympic-sailing',
    publishDate: '2024-04-15',
    hidden: false,
    image: imgNews51,
    title: 'News-Article-51-Title',
    date: 'News-Article-51-Date',
    place: 'News-Article-51-Place',
    summary: 'News-Article-51-S',
    texts: [
      'News-Article-51-P1',
      'News-Article-51-P2',
      'News-Article-51-P3',
      'News-Article-51-P4',
      'News-Article-51-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-launches-exclusive-entrepreneur-accounts',
    publishDate: '2024-02-12',
    hidden: false,
    image: imgNews50,
    title: 'News-Article-50-Title',
    date: 'News-Article-50-Date',
    place: 'News-Article-50-Place',
    summary: 'News-Article-50-S',
    texts: [
      'News-Article-50-P1',
      'News-Article-50-P2',
      'News-Article-50-P3',
      'News-Article-50-P4',
      'News-Article-50-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-team-attending-affiliate-world-dubai-2024',
    publishDate: '2024-02-05',
    hidden: false,
    image: imgNews49,
    title: 'News-Article-49-Title',
    date: 'News-Article-49-Date',
    place: 'News-Article-49-Place',
    summary: 'News-Article-49-S',
    texts: [
      'News-Article-49-P1',
      'News-Article-49-P2',
      'News-Article-49-P3',
      'News-Article-49-P4',
      'News-Article-49-P5',
    ],
  },
  {
    type: NewsType.Article,
    permalink: 'q4-in-review-october-november-and-december-2023',
    publishDate: '2024-01-08',
    hidden: false,
    image: imgNews48,
    title: 'News-Article-48-Title',
    date: 'News-Article-48-Date',
    place: 'News-Article-48-Place',
    summary: 'News-Article-48-S',
    texts: [
      'News-Article-48-P1',
      'News-Article-48-P2',
      'News-Article-48-P3',
      'News-Article-48-P4',
      'News-Article-48-P5',
      'News-Article-48-P6',
      'News-Article-48-P7',
      'News-Article-48-P8',
      'News-Article-48-P9',
      'News-Article-48-P10',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'meet-bivial-at-ifx-expo-in-dubai',
    publishDate: '2023-12-11',
    hidden: false,
    image: imgNews47,
    title: 'News-Article-47-Title',
    date: 'News-Article-47-Date',
    place: 'News-Article-47-Place',
    summary: 'News-Article-47-S',
    texts: [
      'News-Article-47-P1',
      'News-Article-47-P2',
      'News-Article-47-P3',
      'News-Article-47-P4',
      'News-Article-47-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-s-ceo-martynas-bieliauskas-heads-to-web-summit',
    publishDate: '2023-10-30',
    hidden: false,
    image: imgNews46,
    title: 'News-Article-46-Title',
    date: 'News-Article-46-Date',
    place: 'News-Article-46-Place',
    summary: 'News-Article-46-S',
    texts: ['News-Article-46-P1', 'News-Article-46-P2', 'News-Article-46-P3', 'News-Article-46-P4'],
  },
  {
    type: NewsType.Article,
    permalink: 'q3-in-review-july-august-and-september-2023',
    publishDate: '2023-10-09',
    hidden: false,
    image: imgNews45,
    title: 'News-Article-45-Title',
    date: 'News-Article-45-Date',
    place: 'News-Article-45-Place',
    summary: 'News-Article-45-S',
    texts: [
      'News-Article-45-P1',
      'News-Article-45-P2',
      'News-Article-45-P3',
      'News-Article-45-P4',
      'News-Article-45-P5',
      'News-Article-45-P6',
      'News-Article-45-P7',
      'News-Article-45-P8',
      'News-Article-45-P9',
      'News-Article-45-P10',
      'News-Article-45-P11',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-s-chairman-joins-fin-tech-visionaries-at-reflect-festival',
    publishDate: '2023-09-13',
    hidden: false,
    image: imgNews44,
    title: 'News-Article-44-Title',
    date: 'News-Article-44-Date',
    place: 'News-Article-44-Place',
    summary: 'News-Article-44-S',
    texts: ['News-Article-44-P1', 'News-Article-44-P2', 'News-Article-44-P3', 'News-Article-44-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-attending-sibos-annual-conference',
    publishDate: '2023-09-11',
    hidden: false,
    image: imgNews43,
    title: 'News-Article-43-Title',
    date: 'News-Article-43-Date',
    place: 'News-Article-43-Place',
    summary: 'News-Article-43-S',
    texts: [
      'News-Article-43-P1',
      'News-Article-43-P2',
      'News-Article-43-P3',
      'News-Article-43-P4',
      'News-Article-43-P5',
      'News-Article-43-P6',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-attending-the-swiss-fintech-fair',
    publishDate: '2023-08-30',
    hidden: false,
    image: imgNews42,
    title: 'News-Article-42-Title',
    date: 'News-Article-42-Date',
    place: 'News-Article-42-Place',
    summary: 'News-Article-42-S',
    texts: ['News-Article-42-P1', 'News-Article-42-P2', 'News-Article-42-P3'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-attending-wn-conference-2023',
    publishDate: '2023-08-28',
    hidden: false,
    image: imgNews41,
    title: 'News-Article-41-Title',
    date: 'News-Article-41-Date',
    place: 'News-Article-41-Place',
    summary: 'News-Article-41-S',
    texts: [
      'News-Article-41-P1',
      'News-Article-41-P2',
      'News-Article-41-P3',
      'News-Article-41-P4',
      'News-Article-41-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-appoints-markus-emodi-as-chief-legal-compliance-officer',
    publishDate: '2023-08-07',
    hidden: false,
    image: imgNews40,
    title: 'News-Article-40-Title',
    date: 'News-Article-40-Date',
    place: 'News-Article-40-Place',
    summary: 'News-Article-40-S',
    texts: [
      'News-Article-40-P1',
      'News-Article-40-P2',
      'News-Article-40-P3',
      'News-Article-40-P4',
      'News-Article-40-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-launches-usd-denominated-visa-debit-cards',
    publishDate: '2023-07-24',
    hidden: false,
    image: imgNews39,
    title: 'News-Article-39-Title',
    date: 'News-Article-39-Date',
    place: 'News-Article-39-Place',
    summary: 'News-Article-39-S',
    texts: [
      'News-Article-39-P1',
      'News-Article-39-P2',
      'News-Article-39-P3',
      'News-Article-39-P4',
      'News-Article-39-P5',
    ],
  },
  {
    type: NewsType.Article,
    permalink: 'q2-in-review-april-may-and-june-2023',
    publishDate: '2023-07-07',
    hidden: false,
    image: imgNews38,
    title: 'News-Article-38-Title',
    date: 'News-Article-38-Date',
    place: 'News-Article-38-Place',
    summary: 'News-Article-38-S',
    texts: [
      'News-Article-38-P1',
      'News-Article-38-P2',
      'News-Article-38-P3',
      'News-Article-38-P4',
      'News-Article-38-P5',
      'News-Article-38-P6',
      'News-Article-38-P7',
      'News-Article-38-P8',
      'News-Article-38-P9',
      'News-Article-38-P10',
      'News-Article-38-P11',
      'News-Article-38-P12',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-supports-young-athletes-in-swiss-youth-olympic-sailing',
    publishDate: '2023-07-05',
    hidden: false,
    image: imgNews37,
    title: 'News-Article-37-Title',
    date: 'News-Article-37-Date',
    place: 'News-Article-37-Place',
    summary: 'News-Article-37-S',
    texts: ['News-Article-37-P1', 'News-Article-37-P2', 'News-Article-37-P3', 'News-Article-37-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-at-the-affiliate-world-barcelona-2023',
    publishDate: '2023-06-28',
    hidden: false,
    image: imgNews36,
    title: 'News-Article-36-Title',
    date: 'News-Article-36-Date',
    place: 'News-Article-36-Place',
    summary: 'News-Article-36-S',
    texts: [
      'News-Article-36-P1',
      'News-Article-36-P2',
      'News-Article-36-P3',
      'News-Article-36-P4',
      'News-Article-36-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink:
      'klarpay-ag-enhances-global-payment-solutions-with-local-ach-payments-in-40-plus-currencies',
    publishDate: '2023-06-01',
    hidden: false,
    image: imgNews35,
    title: 'News-Article-35-Title',
    date: 'News-Article-35-Date',
    place: 'News-Article-35-Place',
    summary: 'News-Article-35-S',
    texts: [
      'News-Article-35-P1',
      'News-Article-35-P2',
      'News-Article-35-P3',
      'News-Article-35-P4',
      'News-Article-35-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-attending-the-annual-mena-gaming-and-e-sports-summit',
    publishDate: '2023-05-25',
    hidden: false,
    image: imgNews34,
    title: 'News-Article-34-Title',
    date: 'News-Article-34-Date',
    place: 'News-Article-34-Place',
    summary: 'News-Article-34-S',
    texts: ['News-Article-34-P1', 'News-Article-34-P2', 'News-Article-34-P3', 'News-Article-34-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-joins-money-s-biggest-conversation',
    publishDate: '2023-05-22',
    hidden: false,
    image: imgNews33,
    title: 'News-Article-33-Title',
    date: 'News-Article-33-Date',
    place: 'News-Article-33-Place',
    summary: 'News-Article-33-S',
    texts: ['News-Article-33-P1', 'News-Article-33-P2', 'News-Article-33-P3', 'News-Article-33-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-at-seamless-middle-east-2023',
    publishDate: '2023-05-03',
    hidden: false,
    image: imgNews32,
    title: 'News-Article-32-Title',
    date: 'News-Article-32-Date',
    place: 'News-Article-32-Place',
    summary: 'News-Article-32-S',
    texts: [
      'News-Article-32-P1',
      'News-Article-32-P2',
      'News-Article-32-P3',
      'News-Article-32-P4',
      'News-Article-32-P5',
    ],
  },
  {
    type: NewsType.Article,
    permalink: 'q1-in-review-january-february-and-march-2023',
    publishDate: '2023-04-20',
    hidden: false,
    image: imgNews31,
    title: 'News-Article-31-Title',
    date: 'News-Article-31-Date',
    place: 'News-Article-31-Place',
    summary: 'News-Article-31-S',
    texts: [
      'News-Article-31-P1',
      'News-Article-31-P2',
      'News-Article-31-P3',
      'News-Article-31-P4',
      'News-Article-31-P5',
      'News-Article-31-P6',
      'News-Article-31-P7',
      'News-Article-31-P8',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-attending-the-13th-nextgen-payments-and-regtech-forum-2023',
    publishDate: '2023-04-16',
    hidden: false,
    image: imgNews30,
    title: 'News-Article-30-Title',
    date: 'News-Article-30-Date',
    place: 'News-Article-30-Place',
    summary: 'News-Article-30-S',
    texts: ['News-Article-30-P1', 'News-Article-30-P2', 'News-Article-30-P3', 'News-Article-30-P4'],
  },
  {
    type: NewsType.Article,
    permalink: 'five-trends-set-to-transform-fintech-banking-in-2023',
    publishDate: '2023-03-23',
    hidden: false,
    image: imgNews29,
    title: 'News-Article-29-Title',
    date: 'News-Article-29-Date',
    place: 'News-Article-29-Place',
    summary: 'News-Article-29-S',
    texts: [
      'News-Article-29-P1',
      'News-Article-29-P2',
      'News-Article-29-P3',
      'News-Article-29-P4',
      'News-Article-29-P5',
      'News-Article-29-P6',
      'News-Article-29-P7',
      'News-Article-29-P8',
      'News-Article-29-P9',
      'News-Article-29-P10',
      'News-Article-29-P11',
      'News-Article-29-P12',
      'News-Article-29-P13',
      'News-Article-29-P14',
      'News-Article-29-P15',
      'News-Article-29-P16',
      'News-Article-29-P17',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'swiss-fintech-klarpay-ag-achieves-profit-in-the-first-year-of-operations',
    publishDate: '2023-02-14',
    hidden: false,
    image: imgNews28,
    title: 'News-Article-28-Title',
    date: 'News-Article-28-Date',
    place: 'News-Article-28-Place',
    summary: 'News-Article-28-S',
    texts: ['News-Article-28-P1', 'News-Article-28-P2', 'News-Article-28-P3', 'News-Article-28-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-at-the-affiliate-world-dubai',
    publishDate: '2023-02-13',
    hidden: false,
    image: imgNews27,
    title: 'News-Article-27-Title',
    date: 'News-Article-27-Date',
    place: 'News-Article-27-Place',
    summary: 'News-Article-27-S',
    texts: [
      'News-Article-27-P1',
      'News-Article-27-P2',
      'News-Article-27-P3',
      'News-Article-27-P4',
      'News-Article-27-P5',
      'News-Article-27-P6',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-adds-13-new-dedicated-currency-accounts',
    publishDate: '2023-02-01',
    hidden: false,
    image: imgNews25,
    title: 'News-Article-25-Title',
    date: 'News-Article-25-Date',
    place: 'News-Article-25-Place',
    summary: 'News-Article-25-S',
    texts: ['News-Article-25-P1', 'News-Article-25-P2', 'News-Article-25-P3', 'News-Article-25-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-attending-ice-london-2023-event',
    publishDate: '2023-01-27',
    hidden: false,
    image: imgNews24,
    title: 'News-Article-24-Title',
    date: 'News-Article-24-Date',
    place: 'News-Article-24-Place',
    summary: 'News-Article-24-S',
    texts: ['News-Article-24-P1', 'News-Article-24-P2', 'News-Article-24-P3', 'News-Article-24-P4'],
  },
  {
    type: NewsType.Article,
    permalink: 'q4-in-review-october-november-and-december-2022',
    publishDate: '2022-12-27',
    hidden: false,
    image: imgNews23,
    title: 'News-Article-23-Title',
    date: 'News-Article-23-Date',
    place: 'News-Article-23-Place',
    summary: 'News-Article-23-S',
    texts: [
      'News-Article-23-P1',
      'News-Article-23-P2',
      'News-Article-23-P3',
      'News-Article-23-P4',
      'News-Article-23-P5',
      'News-Article-23-P6',
      'News-Article-23-P7',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-builds-a-cloud-based-infrastructure-to-automate-its-services',
    publishDate: '2022-11-23',
    hidden: false,
    image: imgNews22,
    title: 'News-Article-22-Title',
    date: 'News-Article-22-Date',
    place: 'News-Article-22-Place',
    summary: 'News-Article-22-S',
    texts: [
      'News-Article-22-P1',
      'News-Article-22-P2',
      'News-Article-22-P3',
      'News-Article-22-P4',
      'News-Article-22-P5',
    ],
  },
  {
    type: NewsType.Article,
    permalink: 'payments-api-what-it-is-and-how-it-works',
    publishDate: '2022-11-23',
    hidden: false,
    image: imgNews21,
    title: 'News-Article-21-Title',
    date: 'News-Article-21-Date',
    place: 'News-Article-21-Place',
    summary: 'News-Article-21-P1',
    texts: [
      'News-Article-21-P1',
      'News-Article-21-P2',
      'News-Article-21-P3',
      'News-Article-21-P4',
      'News-Article-21-P5',
      'News-Article-21-P6',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-expands-its-international-payments-by-enabling-usd-and-gbp-accounts',
    publishDate: '2022-11-14',
    hidden: false,
    image: imgNews20,
    title: 'News-Article-20-Title',
    date: 'News-Article-20-Date',
    place: 'News-Article-20-Place',
    summary: 'News-Article-20-S',
    texts: ['News-Article-20-P1', 'News-Article-20-P2', 'News-Article-20-P3', 'News-Article-20-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-attending-sigma-worlds-gaming-festival',
    publishDate: '2022-11-08',
    hidden: false,
    image: imgNews19,
    title: 'News-Article-19-Title',
    date: 'News-Article-19-Date',
    place: 'News-Article-19-Place',
    summary: 'News-Article-19-S',
    texts: ['News-Article-19-P1', 'News-Article-19-P2', 'News-Article-19-P3'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-at-the-reflect-festival-2022',
    publishDate: '2022-10-24',
    hidden: false,
    image: imgNews18,
    title: 'News-Article-18-Title',
    date: 'News-Article-18-Date',
    place: 'News-Article-18-Place',
    summary: 'News-Article-18-S',
    texts: ['News-Article-18-P1', 'News-Article-18-P2', 'News-Article-18-P3', 'News-Article-18-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-will-be-at-the-tech-and-entrepreneurial-reflect-festival-2022',
    publishDate: '2022-10-11',
    hidden: false,
    image: imgNews17,
    title: 'News-Article-17-Title',
    date: 'News-Article-17-Date',
    place: 'News-Article-17-Place',
    summary: 'News-Article-17-P1',
    texts: [
      'News-Article-17-P1',
      'News-Article-17-P2',
      'News-Article-17-P3',
      'News-Article-17-P4',
      'News-Article-17-P5',
      'News-Article-17-P6',
      'News-Article-17-P7',
    ],
  },
  {
    type: NewsType.Article,
    permalink: 'q3-in-review-july-august-and-september-2022',
    publishDate: '2022-10-07',
    hidden: false,
    image: imgNews16,
    title: 'News-Article-16-Title',
    date: 'News-Article-16-Date',
    place: 'News-Article-16-Place',
    summary: 'News-Article-16-P1',
    texts: [
      'News-Article-16-P1',
      'News-Article-16-P2',
      'News-Article-16-P3',
      'News-Article-16-P4',
      'News-Article-16-P5',
      'News-Article-16-P6',
      'News-Article-16-P7',
      'News-Article-16-P8',
      'News-Article-16-P9',
      'News-Article-16-P10',
      'News-Article-16-P11',
      'News-Article-16-P12',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-at-the-eurofinance-international-treasury-management-event',
    publishDate: '2022-09-28',
    hidden: false,
    image: imgNews13,
    title: 'News-Article-15-Title',
    date: 'News-Article-15-Date',
    place: 'News-Article-15-Place',
    summary: 'News-Article-15-P1',
    texts: [
      'News-Article-15-P1',
      'News-Article-15-P2',
      'News-Article-15-P3',
      'News-Article-15-P4',
      'News-Article-15-P5',
      'News-Article-15-P6',
      'News-Article-15-P7',
    ],
  },
  {
    type: NewsType.Article,
    permalink: 'virtual-iban-what-it-means-and-how-it-benefits-your-business',
    publishDate: '2022-09-23',
    hidden: false,
    image: imgNews14,
    title: 'News-Article-14-Title',
    date: 'News-Article-14-Date',
    place: 'News-Article-14-Place',
    summary: 'News-Article-14-P1',
    texts: [
      'News-Article-14-P1',
      'News-Article-14-P2',
      'News-Article-14-P3',
      'News-Article-14-P4',
      'News-Article-14-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-attending-the-eurofinance-international-treasury-management-event',
    publishDate: '2022-09-15',
    hidden: false,
    image: imgNews13,
    title: 'News-Article-13-Title',
    date: 'News-Article-13-Date',
    place: 'News-Article-13-Place',
    summary: 'News-Article-13-P1',
    texts: [
      'News-Article-13-P1',
      'News-Article-13-P2',
      'News-Article-13-P3',
      'News-Article-13-P4',
      'News-Article-13-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-joins-dukascopy-bank-s-discussion-panel',
    publishDate: '2022-09-08',
    hidden: false,
    image: imgNews12,
    title: 'News-Article-12-Title',
    date: 'News-Article-12-Date',
    place: 'News-Article-12-Place',
    summary: 'News-Article-12-P1',
    texts: [
      'News-Article-12-P1',
      'News-Article-12-P2',
      'News-Article-12-P3',
      'News-Article-12-P4',
      'News-Article-12-P5',
      'News-Article-12-P6',
      'News-Article-12-P7',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-at-the-webmaster-access-event',
    publishDate: '2022-08-29',
    hidden: false,
    image: imgNews11,
    title: 'News-Article-11-Title',
    date: 'News-Article-11-Date',
    place: 'News-Article-11-Place',
    summary: 'News-Article-11-P1',
    texts: ['News-Article-11-P1', 'News-Article-11-P2', 'News-Article-11-P3', 'News-Article-11-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-sponsors-the-webmaster-acccess-hybrid-conference-2022',
    publishDate: '2022-08-22',
    hidden: false,
    image: imgNews10,
    title: 'News-Article-10-Title',
    date: 'News-Article-10-Date',
    place: 'News-Article-10-Place',
    summary: 'News-Article-10-P1',
    texts: ['News-Article-10-P1', 'News-Article-10-P2', 'News-Article-10-P3', 'News-Article-10-P4'],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-adds-70+-currencies-to-enable-cross-border-international-payments',
    publishDate: '2022-08-18',
    hidden: false,
    image: imgNews9,
    title: 'News-Article-9-Title',
    date: 'News-Article-9-Date',
    place: 'News-Article-9-Place',
    summary: 'News-Article-9-P1',
    texts: ['News-Article-9-P1', 'News-Article-9-P2', 'News-Article-9-P3', 'News-Article-9-P4'],
  },
  {
    type: NewsType.Article,
    permalink: 'the-benefits-of-google-pay-for-klarpay-merchants',
    publishDate: '2022-07-27',
    hidden: false,
    image: imgNews8,
    title: 'News-Article-8-Title',
    date: 'News-Article-8-Date',
    place: 'News-Article-8-Place',
    summary: 'News-Article-8-P1',
    texts: [
      'News-Article-8-P1',
      'News-Article-8-P2',
      'News-Article-8-P3',
      'News-Article-8-P4',
      'News-Article-8-P5',
      'News-Article-8-P6',
      'News-Article-8-P7',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'google-pay-now-available-with-klarpay-debit-cards',
    publishDate: '2022-07-27',
    hidden: false,
    image: imgNews7,
    title: 'News-Article-7-Title',
    date: 'News-Article-7-Date',
    place: 'News-Article-7-Place',
    summary: 'News-Article-7-P1',
    texts: ['News-Article-7-P1', 'News-Article-7-P2', 'News-Article-7-P3', 'News-Article-7-P4'],
  },
  {
    type: NewsType.Article,
    permalink: 'what-are-the-benefits-of-apple-pay-for-klarpay-merchants',
    publishDate: '2022-07-14',
    hidden: false,
    image: imgNews6,
    title: 'News-Article-6-Title',
    date: 'News-Article-6-Date',
    place: 'News-Article-6-Place',
    summary: 'News-Article-6-P1',
    texts: [
      'News-Article-6-P1',
      'News-Article-6-P2',
      'News-Article-6-P3',
      'News-Article-6-P4',
      'News-Article-6-P5',
      'News-Article-6-P6',
      'News-Article-6-P7',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'apple-pay-now-available-with-klarpay-debit-cards',
    publishDate: '2022-07-13',
    hidden: false,
    image: imgNews5,
    title: 'News-Article-5-Title',
    date: 'News-Article-5-Date',
    place: 'News-Article-5-Place',
    summary: 'News-Article-5-P1',
    texts: [
      'News-Article-5-P1',
      'News-Article-5-P2',
      'News-Article-5-P3',
      'News-Article-5-P4',
      'News-Article-5-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-becomes-new-visa-principal-member',
    publishDate: '2022-07-13',
    hidden: false,
    image: imgNews4,
    title: 'News-Article-4-Title',
    date: 'News-Article-4-Date',
    place: 'News-Article-4-Place',
    summary: 'News-Article-4-P1',
    texts: [
      'News-Article-4-P1',
      'News-Article-4-P2',
      'News-Article-4-P3',
      'News-Article-4-P4',
      'News-Article-4-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-raises-chf-3m-to-accelerate-growth',
    publishDate: '2022-05-13',
    hidden: false,
    image: imgNews3,
    title: 'News-Article-3-Title',
    date: 'News-Article-3-Date',
    place: 'News-Article-3-Place',
    summary: 'News-Article-3-P1',
    texts: [
      'News-Article-3-P1',
      'News-Article-3-P2',
      'News-Article-3-P3',
      'News-Article-3-P4',
      'News-Article-3-P5',
      'News-Article-3-P6',
      'News-Article-3-P7',
      'News-Article-3-P8',
      'News-Article-3-P9',
    ],
  },
  {
    type: NewsType.News,
    permalink: 'klarpay-ag-appoints-marc-evans-as-chief-operating-officer-and-deputy-cfo',
    publishDate: '2022-05-13',
    hidden: false,
    image: imgNews2,
    title: 'News-Article-2-Title',
    date: 'News-Article-2-Date',
    place: 'News-Article-2-Place',
    summary: 'News-Article-2-P1',
    texts: [
      'News-Article-2-P1',
      'News-Article-2-P2',
      'News-Article-2-P3',
      'News-Article-2-P4',
      'News-Article-2-P5',
    ],
  },
  {
    type: NewsType.News,
    permalink:
      'klarpay-the-first-fintech-company-offering-modern-swiss-accounts-for-digital-businesses',
    publishDate: '2023-03-21',
    hidden: false,
    image: imgNews1,
    title: 'News-Article-1-Title',
    date: 'News-Article-1-Date',
    place: 'News-Article-1-Place',
    summary: 'News-Article-1-P1',
    texts: [
      'News-Article-1-P1',
      'News-Article-1-P2',
      'News-Article-1-P3',
      'News-Article-1-P4',
      'News-Article-1-P5',
      'News-Article-1-P6',
      'News-Article-1-P7',
      'News-Article-1-P8',
      'News-Article-1-P9',
    ],
  },
]
