import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  mediaObjectWrapper: {
    width: '100%',
    maxWidth: '1100px',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(12),
  },
  media: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
      margin: '0 auto',
    },
  },
  media100: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
      margin: '0 auto',
    },
  },
}))

type MediaObjectImageProps = { image: string; size: 'small' | 'large'; fullHeight: boolean }

const MediaObjectImage: FC<MediaObjectImageProps> = ({ image, size, fullHeight }) => {
  const classes = useStyles()

  const selectedClass = fullHeight ? classes.media100 : classes.media

  return (
    <Grid item xs={12} md={size === 'small' ? 5 : 6}>
      <CardMedia component="img" alt="image" image={image} className={selectedClass} />
    </Grid>
  )
}

type MediaObjectContentProps = { size: 'small' | 'large' }

const MediaObjectContent: FC<MediaObjectContentProps> = ({ children, size }) => {
  return (
    <Grid item xs={12} md={size === 'small' ? 6 : 5}>
      {children}
    </Grid>
  )
}

export const MediaObject: FC<{
  children: React.ReactNode
  image: string
  imagePlacement: 'left' | 'right'
  imageSize?: 'small' | 'large'
  fullHeight?: boolean
}> = ({ children, image, imagePlacement, imageSize = 'small', fullHeight = true }) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.mediaObjectWrapper}>
        <Hidden smDown>
          <Grid container>
            {imagePlacement === 'left' ? (
              <MediaObjectImage image={image} size={imageSize} fullHeight={fullHeight} />
            ) : (
              <MediaObjectContent size={imageSize}>{children}</MediaObjectContent>
            )}
            <Hidden smDown>
              <Grid item xs={12} md={1} />
            </Hidden>
            {imagePlacement === 'right' ? (
              <MediaObjectImage image={image} size={imageSize} fullHeight={fullHeight} />
            ) : (
              <MediaObjectContent size={imageSize}>{children}</MediaObjectContent>
            )}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <MediaObjectImage image={image} size={'small'} fullHeight={fullHeight} />
          <MediaObjectContent size={imageSize}>{children}</MediaObjectContent>
        </Hidden>
      </Box>
    </>
  )
}
