import * as React from "react";
const SvgIconArrowUp = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", width: 24, height: 24, viewBox: "0 0 1000 1000", ...props }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" }), /* @__PURE__ */ React.createElement("path", { d: "M10.063 5.499V23h2.874V5.5l8.05 8.05L23 11.5 11.5 0 0 11.5l2.013 2.049z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  isCustomFont: "none",
  fontFileUrl: "none",
  fill: "#000",
  fillRule: "nonzero",
  opacity: 1
}, transform: "matrix(39.5257 0 0 -39.5257 45.455 954.554)", vectorEffect: "non-scaling-stroke" }));
export default SvgIconArrowUp;
